import {defineMessages} from 'react-intl';

import localization from "../../../services/localization";

export const airlineRatingChangesMessages = defineMessages({
  allAirlines: {
    id: 'AirlineRatingChangesPreferenceModal.constants.allAirlines',
    defaultMessage: 'All Airlines'
  },
});

export const AIRLINE_RATING_CHANGES_FIELDS = {
  NAME: 'name',
  EMAIL: 'primaryEmail',
  EMAIL_ENABLED: 'emailEnabled',
  AIRLINE_IDS: 'airlineIds'
};

export const ALL_AIRLINES = 'allAirlines';

export const ALL_AIRLINE_SELECT_OPTION = {
  value: ALL_AIRLINES,
  label: localization.formatMessage(airlineRatingChangesMessages?.allAirlines)
};
