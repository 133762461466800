import {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {validate} from 'uuid';

import {defineMessages, FormattedMessage} from 'react-intl';
import {Col, Row, Skeleton} from 'antd';

import BasePage from '../App/BasePage/BasePage';
import HeaderBar from '../App/HeaderBar/HeaderBar';
import BasePageHeader from '../App/BasePage/BasePageHeader';
import HorizonCard from '../HorizonCard/HorizonCard';
import useReadReceipt from './useReadReceipt';

const messages = defineMessages({
  pageHeading: {
    id: 'ReadReceiptPage.pageHeading',
    defaultMessage: 'Communication'
  },
  pageTitle: {
    id: 'ReadReceiptPage.pageTitle',
    defaultMessage: 'Read Receipt'
  },
  successInfo: {
    id: 'ReadReceiptPage.successInfo',
    defaultMessage: 'Thank you! Your acknowledgement has been recorded.'
  },
  failureInfo: {
    id: 'ReadReceiptPage.failureInfo',
    defaultMessage: 'The page failed to load. Please retry the link.'
  },
  invalidTrackingId: {
    id: 'ReadReceiptPage.invalidTrackingId',
    defaultMessage: 'The page failed to load. Invalid data please contact support'
  }
});

export default function ReadReceiptPage() {
  const {params: {trackingId}} = useRouteMatch();

  const {submitReadReceipt} = useReadReceipt();

  const [loadedMessage, setLoadedMessage] = useState(false);

  /**
   * if tracking id exist and is valid trigger submission of read receipt
   * if tracking id exist but is not valid display invalid tracking id info
   */
  useEffect(() => {
    if (trackingId) {
      if(validate(trackingId)){
        submitReadReceipt(trackingId)
          .then(() => {
            setLoadedMessage(messages.successInfo);
          })
          .catch(() => {
            setLoadedMessage(messages.failureInfo);
          });
      }
      else{
        setLoadedMessage(messages.invalidTrackingId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingId]);

  return (
    <div className="ReadReceiptPage">
      <HeaderBar/>

      <BasePage
        pageTitleIntl={messages.pageTitle}
        header={(
          <BasePageHeader
            heading={<FormattedMessage {...messages.pageHeading} />}
            title={<FormattedMessage {...messages.pageTitle} />}
          />
        )}
      >
        <Skeleton loading={!loadedMessage}>
          <Row gutter={16}>
            <Col sm={24} md={18} lg={12}>
              <HorizonCard>
                {loadedMessage && (
                  <FormattedMessage {...loadedMessage}/>
                )}
              </HorizonCard>
            </Col>
          </Row>
        </Skeleton>
      </BasePage>
    </div>
  );
}