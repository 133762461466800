import * as types from './types';

const reducer = (
  state = {
    loaded: false,
    saving: false,
    workingAlertNotifications: false,
    alertNotifications: undefined,
    incidentNotifications: undefined,
    fetchingIncidentNotifications: false,
    languageNotifications: undefined,
    savePreferencesError: false,
    fetchingAlertNotifications: false,
    fetchingLanguageNotifications: false,
    addLanguageNotifications: false,
    notificationLangInitialized: false,
    languageNotificationError: false,
    preferenceUserId: undefined,
    skipLanguageNotifSetup: false,
    aggregatedPermissionIds: undefined,
    aggregatedPermissionIdsLoading: false,
    userId: undefined,
    isSelf: undefined,
    flaggedPreferences: undefined
  },
  action
) => {
  switch (action.type) {
    case types.SAVE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        saving: true,
        savePreferencesError: false
      };

    case types.SAVE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        saving: false,
        savePreferencesError: false
      };

    case types.SAVE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        saving: false,
        savePreferencesError: true
      };

    case types.GET_ALERT_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        workingAlertNotifications: true,
        fetchingAlertNotifications: true
      };

    case types.GET_ALERT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        alertNotifications: action.payload,
        workingAlertNotifications: false,
        fetchingAlertNotifications: false
      };

    case types.GET_ALERT_NOTIFICATIONS_ERROR:
      return {
        ...state,
        workingAlertNotifications: false,
        fetchingAlertNotifications: false
      };

    case types.GET_INCIDENT_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        fetchingIncidentNotifications: true
      };

    case types.GET_INCIDENT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        incidentNotifications: action.payload,
        fetchingIncidentNotifications: false
      };

    case types.GET_INCIDENT_NOTIFICATIONS_ERROR:
      return {
        ...state,
        fetchingIncidentNotifications: false
      };

    case types.GET_LANGUAGE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        fetchingLanguageNotifications: true,
        preferenceUserId: action.payload,
        skipLanguageNotifSetup: false
      };

    case types.GET_LANGUAGE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        languageNotifications: action.payload,
        fetchingLanguageNotifications: false,
        notificationLangInitialized: true,
        languageNotificationError: false
      };

    case types.GET_LANGUAGE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        fetchingLanguageNotifications: false,
        notificationLangInitialized: true,
        languageNotificationError: true,
        skipLanguageNotifSetup: true
      };

    case types.ADD_LANGUAGE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        addLanguageNotifications: true
      };

    case types.ADD_LANGUAGE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        addLanguageNotifications: false
      };

    case types.ADD_LANGUAGE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        addLanguageNotifications: false,
        skipLanguageNotifSetup: true
      };

    case types.SAVE_LANGUAGE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        saving: true
      };

    case types.SAVE_LANGUAGE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        saving: false
      };

    case types.SAVE_LANGUAGE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        saving: false,
        skipLanguageNotifSetup: true
      };

    case types.ADD_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        saving: true,
        addPreferencesError: false
      };

    case types.ADD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        saving: false,
        addPreferencesError: false
      };

    case types.ADD_NOTIFICATIONS_ERROR:
      return {
        ...state,
        saving: false,
        addPreferencesError: true
      };

    case types.GET_AGG_PERMISSIONS_IDS_REQUEST:
      return {
        ...state,
        aggregatedPermissionIdsLoading: true,
        aggregatedPermissionIds: undefined,
        userId: action.payload.userId,
        isSelf: action.payload.isSelf
      };

    case types.GET_AGG_PERMISSIONS_IDS_SUCCESS:
      return {
        ...state,
        aggregatedPermissionIdsLoading: false,
        aggregatedPermissionIds: action.payload
      };
    case types.SET_FLAGGED_NOTIFICATION_PREFERENCES:
      return {
        ...state,
        flaggedPreferences: action.payload,
      };

    case types.GET_AGG_PERMISSIONS_IDS_ERROR:
      return {
        ...state,
        aggregatedPermissionIdsLoading: false
      };

    default:
      return state;
  }
};

export default reducer;
