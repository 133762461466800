import articles from './articles';
import auth from './authentication';
import categories from './categories';
import communicationHistory from './communication-history';
import contact from './contact-us';
import geoLocations from './geo-locations';
import intel from './intel';
import organization from './organization';
import users from './users';
import resources from './resources';
import selfRegistration from './self-registration';
import preferences from './preferences';
import ratings from './ratings';
import zendesk from './zendesk';
import reports from './reports';
import persons from './persons';
import checkIns from './checkin';
import alsat from './alsat';
import branding from './branding';
import templates from './templates';
import geoThreatZones from './geo-threat-zones';
import exposures from './exposures';
import checkinNotifications from './checkin-notifications';
import itinerary from "./itinerary";
import airlines from './airlines';
import communicator from './communicator';
import collinsonAuth from './collinson-auth';
import sites from './sites';
import pnr from './pnr';
import briefs from './briefs';
import incidents from './incidents';
import autoStatusConfirmation from './auto-status-confirmation';
import travelApproval from './travel-approval';
import xsrf from './xsrf';

export default {
  articles,
  auth,
  categories,
  communicationHistory,
  contact,
  geoLocations,
  intel,
  ratings,
  organization,
  users,
  resources,
  selfRegistration,
  preferences,
  zendesk,
  reports,
  persons,
  checkIns,
  alsat,
  branding,
  templates,
  geoThreatZones,
  exposures,
  checkinNotifications,
  itinerary,
  airlines,
  communicator,
  collinsonAuth,
  sites,
  pnr,
  briefs,
  incidents,
  autoStatusConfirmation,
  travelApproval,
  xsrf
};
