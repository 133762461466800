export const TEST_ENVIRONMENTS = ['QA', 'QA-LOCAL', 'DEV', 'DEV-LOCAL'];
export const LIVE_ENVIRONMENTS = ['STAGE', 'PROD-LOCAL', 'PROD'];

export const FEATURES = {
  XSRF: true,
  PRM_438: true,
  MGNM_755: true,
  MGNM_530: true,
  TRM_68: true,
  MAPI_107: true,
  MAPI_128: true,
  MAPI_272: true,
  TRM_511: true,
  HORIZONWEB_6740: false,
  PRM_585: true,
  TBA_18: true,
  TRM_605: true,
  TBA_17: true,
  MGNM_678: true,
  PRM_649: true,
  PRM_487: true,
  PRM_660: true,
  PRM_664: true,
  PRM_633: true,
  PRM_662: true,
  MGNM_747: true,
  MGNM_843: false,
  DIG_748: true,
  MASSNOTIF_736: true,
  MASSNOTIF_765: true,
  MGNMSTS_225: true,
  TBA_74: true,
  MGNM_825: true,
  PRM_618: true,
  TBA_199: true,
  TBA_86: true,
  PRM_508: true,
  MGNMSTS_383: true,
  INTR_15: false,
  MASSNOTIF_784: true,
  TBA_152: true,
  MGNM_683: true,
  TBA_198: true,
  TBA_223: true,
  TBA_225: true,
  MGNMSTS_384: true,
  PRM_733: true,
  MGNM_844: false,
  MGNM_842: false,
  MGNM_802: true,
  MAPI_228: true,
  TRM_696: true,
  MGNMSTS_390: true,
  MGNMSTS_391: true,
  TBA_94: true,
  MAPI_270: true,
  MAPI_191: false,
  MGNM_469: true,
  MGNM_625: false,
  MASSNOTIF_757: false,
  TBA_228: true,
  PRM_623: true,
  FUZ_42: false, // also includes FUZ_50,
  PRM_764: true,
  PRM_802: false,
  PRM_799: true,
  PRM_699: true,
  INTH_332: true,
  MASSNOTIF_758: false,
  INTR_1: false,
  MGNM_583: false,
  MAPI_274: false,
  INTH_144: false,
  TRM_708: false,
  INTR_165: false,
  PDM_15: false,
  PRM_753: false
};

export const LOCAL_STORAGE_KEY = 'FEATURE_TOGGLE_ENABLE_OVERRIDE';
