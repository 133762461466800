import waRequest from './wa-request';
import waHttp from './wa-http';

export const baseApi = '/users/v1';

/**
 * Initiate authenticated get ${baseApi}/users/self with reties for the case of FSSO account self has yet
 * to be completed due to delay in event bus consumption
 *
 * @returns {Promise}
 */
function getSelf(locale) {
  const url = `${baseApi}/users/self`;

  return waRequest.requestWithRetry({
    url: url,
    method: 'get',
    params: {
      locale
    }
  }, 60, 30000);
}

function getSelfConfigPattern({params: {locale}}) {
  return getSelf(locale);
}

/**
 * Initiate authenticated GET ${baseApi}/organizations/${organizationId}/users/${userId}
 *
 * @param userId
 * @param organizationId
 * @param locale
 * @returns {Promise}
 */
function getUser(userId, organizationId, locale) {
  return getUserConfigPattern({pathParams: {userId, organizationId}, params: {locale}});
}

function getUserConfigPattern({pathParams: {userId, organizationId}, params: {locale, includePermissions}, signal}) {
  const url = `${baseApi}/organizations/${organizationId}/users/${userId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale,
      includePermissions
    },
    signal
  });
}

/**
 * Initiate authenticated GET ${baseApi}/organizations/{organizationId}/users
 *
 * @param page
 * @param size
 * @param sort
 * @param filterString
 * @param roleDefinitionId
 * @param organizationId
 * @returns {Promise}
 */
function getUsersList(page, size, sort, filterString, roleDefinitionId, organizationId) {
  const url = `${baseApi}/organizations/${organizationId}/users`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      page,
      size,
      sort,
      filterString,
      roleDefinitionId
    }
  });
}

/**
 * Initiate authenticated GET ${baseApi}/organizations/{organizationId}/users
 *
 * use this for hooks (using the standard config pattern)
 * @param pathParams organizationId
 * @param params page, size, sort, filterString, and roleDefinitionId query parameters
 * @param signal
 * @returns {Promise}
 */
function getUsersListConfigPattern({pathParams, params, signal}) {

  const {organizationId} = pathParams;

  const {
    page,
    size,
    sort,
    filterString,
    roleDefinitionId,
    organizationIds,
    statuses
  } = params;

  const url = `${baseApi}/organizations/${organizationId}/users`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      page,
      size,
      sort,
      filterString,
      roleDefinitionId,
      organizationIds,
      statuses
    },
    signal
  });
}

/**
 * Initiate authenticated POST ${baseApi}/organizations/${organizationId}/users to create a new organization user
 *
 * Creates a new User who will be associated with one or more Organization Roles within an Organization hierarchy.
 *
 * The Organization Roles provided in the request MUST be associated with the Organization from the path (either directly or through the hierarchy). See GET /organizations/{organizationId}/roles for an API for getting the roles available for a particular Organization. At least one OrganizationRole must be specified in the create request.
 *
 * @param {object} configData contains organizationId and user
 * @returns {Promise}
 */
function createOrganizationUser(configData) {

  const {organizationId, user} = configData;

  const url = `${baseApi}/organizations/${organizationId}/users`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: user
  });
}

/**
 * Initiate authenticated PUT `${baseApi}/organizations/${orgId}/users/${userId}`
 *
 * * @param {object} configData contains userId, orgId, and payload properties
 * @returns {Promise}
 */
function updateUserConfigPattern(configData) {

  const {orgId, userId, payload} = configData;

  const url = `${baseApi}/organizations/${orgId}/users/${userId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: payload
  });
}

/**
 * Initiate authenticated GET ${baseApi}/organizations/{organizationId}/users
 *
 * @returns {Promise}
 */
function getOrganizationUsersList(organizationId, roleDefinitionId) {
  const url = `${baseApi}/organizations/${organizationId}/users`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      roleDefinitionId
    }
  });
}

/**
 * Initiate authenticated GET ${baseApi}/organizations/{organizationId}/subscriptions
 *
 * @param {string} organizationId
 * @param {string} locale
 * @returns {Promise}
 */
function getOrganizationSubscriptions(organizationId, locale = 'en') {

  const url = `${baseApi}/organizations/${organizationId}/subscriptions`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });

}

/**
 * Initiate authenticated GET ${baseApi}/organizations/${organizationId}/roles
 *
 * @param organizationId
 * @param {string} locale
 * @returns {Promise} an array of role objects
 */
function getOrganizationRoles(organizationId, locale = 'en') {
  const url = `${baseApi}/organizations/${organizationId}/roles`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });
}

/**
 * Initiate authenticated GET ${baseApi}/organizations/${organizationId}/roles
 *
 * @param organizationId
 * @param {string} locale
 * @returns {Promise} an array of role objects
 */
function getOrganizationRolesConfigPattern({pathParams: {organizationId}, params: {locale = 'en'}}) {
  const url = `${baseApi}/organizations/${organizationId}/roles`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });
}

/**
 * Initiate authenticated GET ${baseApi}/role-definitions
 *
 * @returns {Promise}
 */
function getStandardRoles(locale = 'en') {

  const url = `${baseApi}/role-definitions`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });

}

/**
 * Initiate authenticated PUT ${baseApi}/v1/organizations/{organizationId}/subscriptions
 *
 * @returns {Promise}
 */
function updateOrganizationSubscriptions(organizationId, subscriptions) {

  const url = `${baseApi}/organizations/${organizationId}/subscriptions`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: subscriptions
  });

}

/**
 * Initiate authenticated GET /organizations/v1/organizations/${organizationId}/users/active-count:
 *
 * @param organizationId
 * @returns {Promise}
 */
function getOrganizationActiveSeats(organizationId) {
  const url = `${baseApi}/organizations/${organizationId}/users/active-count`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * Initiate authenticated GET ${baseApi}/product-solutions
 *
 * @returns {Promise}
 */
function getProductSolutions(locale = 'en') {

  const url = `${baseApi}/product-solutions`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });

}

function createCustomRole(organizationId, rolePayload) {
  const url = `${baseApi}/organizations/${organizationId}/custom-roles`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: rolePayload
  });
}

/**
 * Initiate authenticated GET ${baseApi}/organizations/${organizationId}/users/count
 *
 * @param organizationId
 * @param roleDefinitionId
 * @returns {Promise}
 */
function getOrganizationUserCountForRole(organizationId, roleDefinitionId) {

  const url = `${baseApi}/organizations/${organizationId}/users/count`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      roleDefinitionId
    }
  });
}

/**
 * Initiate authenticated GET ${baseApi}/organizations/${organizationId}/roles/${organizationRoleId}
 *
 * @param organizationId
 * @param organizationRoleId
 * @param {string} locale
 * @returns {Promise} an array of role objects
 */
function getOrganizationRoleDetail(organizationId, organizationRoleId, locale = 'en') {
  const url = `${baseApi}/organizations/${organizationId}/roles/${organizationRoleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });
}

/**
 * Initiate authenticated PUT ${baseApi}/organizations/${organizationId}/custom-roles/${organizationRoleId}
 *
 * @param organizationId
 * @param organizationRoleId
 * @param customRole
 * @returns {Promise} an array of role objects
 */
function updateOrganizationCustomRole(organizationId, organizationRoleId, customRole) {
  const url = `${baseApi}/organizations/${organizationId}/custom-roles/${organizationRoleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: customRole
  });
}

/**
 * Initiate authenticated DELETE ${baseApi}/organizations/${organizationId}/custom-roles/${organizationRoleId}
 *
 * @param organizationId
 * @param organizationRoleId
 * @returns {Promise} an array of role objects
 */
function deleteOrganizationCustomRole(organizationId, organizationRoleId) {
  const url = `${baseApi}/organizations/${organizationId}/custom-roles/${organizationRoleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

/**
 * Bulk re-assign all the users previously assigned to one role, to another role in the organization
 *
 * Initiate authenticated PUT ${baseApi}/organizations/${organizationId}/users/reassign-role
 *
 * @param organizationId
 * @param roleDefinitionIdToRemove
 * @param roleDefinitionIdToAdd
 */
function putBulkReassign(organizationId, roleDefinitionIdToRemove, roleDefinitionIdToAdd) {
  const url = `${baseApi}/organizations/${organizationId}/users/reassign-role`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: {roleDefinitionIdToRemove, roleDefinitionIdToAdd}
  });
}

/**
 * Bulk assign a role to all users in the organization
 *
 * Initiate authenticated PUT ${baseApi}/organizations/${organizationId}/users/assign-role
 * @param {*} organizationId
 * @param {*} roleDefinitionId
 */
function putBulkAssign(organizationId, roleDefinitionId) {
  const url = `${baseApi}/organizations/${organizationId}/users/assign-role`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: {roleDefinitionId}
  });
}

/**
 * Update user's organization roles assignments
 *
 * Initiate authenticated PUT ${baseApi}/organizations/${DEFAULT_PARENT_ID}/users/${userId}/roles
 *
 * @param organizationId
 * @param userId
 * @param roleUpdates
 * @returns {Promise}
 */
function updateUserOrganizationRoles(organizationId, userId, roleUpdates) {
  const url = `${baseApi}/organizations/${organizationId}/users/${userId}/roles`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: roleUpdates
  });
}

/**
 * Initiate UN-authenticated POST ${apiBase}/users/resend-invite
 * @param username
 * @returns {Promise}
 */
function resendInvite(username) {
  const baseURL = process.env.REACT_APP_GATEWAY_URL;
  const url = `${baseApi}/users/resend-invite`;

  return waHttp.httpRequest({
    baseURL,
    url: url,
    method: 'post',
    params: {username}
  });
}

/**
 * Initiate authenticated GET ${apiBase}/organizations/${organizationId}/languages, to get the
 * list of languages an organization has access to
 *
 * @param organizationId
 * @returns {Promise}
 */
function getOrganizationLanguages(organizationId) {
  const url = `${baseApi}/organizations/${organizationId}/languages`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * Initiate UN-authenticated GET ${baseApi}/organizations/languages
 *
 * @param clientUrlString
 * @returns {Promise}
 */
function getCobrandLanguages(clientUrlString) {
  const baseURL = process.env.REACT_APP_GATEWAY_URL;
  const url = `${baseApi}/organizations/languages`;

  return waHttp.httpRequest({
    baseURL,
    url: url,
    method: 'get',
    params: {clientUrlString}
  });
}

// CONFIG PATTERN
function getCobrandLanguagesConfigPattern({params: {clientUrlString}}, signal) {
  const baseURL = process.env.REACT_APP_GATEWAY_URL;
  const url = `${baseApi}/organizations/languages`;

  return waHttp.httpRequest({
    baseURL,
    url: url,
    method: 'get',
    params: {clientUrlString},
    signal
  });
}

/**
 * @returns {Promise}
 */
function getUsagePlans() {
  const url = `${baseApi}/app-users/usage-plans`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * @returns {Promise}
 */
function createApiUser(apiUser) {
  const url = `${baseApi}/app-users`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: apiUser
  });
}

function getApiUsersList(includeRootOrg = false) {
  const url = `${baseApi}/app-users`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {includeRootOrg}
  });
}

function getApiUsersListConfigPattern({params}, signal) {

  const {includeRootOrg = false} = params;

  const url = `${baseApi}/app-users`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      includeRootOrg
    },
    signal
  });
}

function getApiUserDetails(apiUserId) {
  const url = `${baseApi}/app-users/${apiUserId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function updateApiUser(configData) {

  const {
    pathParams: {apiUserId},
    data: apiUser
  } = configData;

  const url = `${baseApi}/app-users/${apiUserId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: apiUser
  });
}

function deleteApiUser(configData) {
  const {
    pathParams: {
      apiUserId
    }
  } = configData;

  const url = `${baseApi}/app-users/${apiUserId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

function getAggregatedPermissionsPerId(organizationId, userId) {
  const url = `${baseApi}/organizations/${organizationId}/users/${userId}/permissions`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

// replace getAggregatedPermissionsPerId with this later
function getAggregatedPermissionsPerIdConfigPattern({pathParams: {organizationId, userId}}) {
  const url = `${baseApi}/organizations/${organizationId}/users/${userId}/permissions`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * Initiate authenticated get ${baseApi}/users/self/organizations. response
 * will contain tree of organizations that user has some connection with.
 * Branches above user access will be limited to just track info to know
 * what is up the tree while organization the user has access to will have
 * slight more data but still minimal to keep payload small and api speed fast
 *
 * @returns {Promise}
 */
function getSelfOrganizations() {
  const url = `${baseApi}/users/self/organizations`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/** updateUserByAction
 *
 * @param {Object} configData This object contains a single element, 'pathParams'
 * @param {string} configData.userId The id of the user.
 * @param {string} configData.organizationId The organizationId affected by the request.
 * @param {string} configData.action The action to perform.
 *
 */
function updateUserByAction(configData) {

  const {
    pathParams: {
      userId,
      organizationId,
      action
    }
  } = configData;

  const url = `${baseApi}/organizations/${organizationId}/users/${userId}/${action}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put'
  });
}

/**
 * Initiate authenticated POST ${baseApi}/users/summary-list to get list of users from Ids
 *
 * @returns {Promise}
 * @param usersList
 */
function getListOfUsersByIds({data: {usersList}}) {

  const url = `${baseApi}/users/summary-list`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: usersList
  });
}

/**
 * getAttributeRestrictions
 * @returns {Promise}
 */
function getAttributeRestrictions(configData) {

  const {
    pathParams: {organizationId}
  } = configData;

  const url = `${baseApi}/organizations/${organizationId}/attribute-restrictions`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * getAttributeRestrictionType
 * @returns {Promise}
 */
function getAttributeRestrictionType({pathParams: {organizationId, restrictionType}}) {

  const url = `${baseApi}/organizations/${organizationId}/attribute-restrictions/${restrictionType}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/** updateAttributeRestrictions
 *
 * @param {Object} configData This object contains 'pathParams' an 'data'
 * @param {string} configData.organizationId The organizationId affected by the request.
 *
 */
function updateAttributeRestrictions(configData) {

  const {
    pathParams: {
      organizationId
    },
    data
  } = configData;

  const url = `${baseApi}/organizations/${organizationId}/attribute-restrictions`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data
  });
}

function enforceUser({params, data}) {

  const url = `${baseApi}/enforce`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params,
    data
  });
}

function exportUserReport({pathParams: {organizationId}, params: {sort, filterString, roleDefinitionId, createUrl, locale}, data}) {
  const url = `${baseApi}/organizations/${organizationId}/users/search/export`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      sort,
      filterString,
      roleDefinitionId,
      locale,
      createUrl
    },
    data
  });
}

function exportUserReportStatus({pathParams: {exportId}, params: {successCallback}}) {

  const url = `${baseApi}/users/search/export/${exportId}`;

  return waRequest.requestWithRetryUntilSuccess({
    url: url,
    method: 'get'
  },
  successCallback
  );
}

function exportUserReportDownload(exportId) {

  const url = `${baseApi}/users/search/export/${exportId}/report`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob'
  });
}

function resetMfaPreferences({pathParams: {organizationId, userId}}) {

  const url = `${baseApi}/organizations/${organizationId}/users/${userId}/mfa-preferences/reset`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post'
  });
}

function setSelfMfaPreferences({data}) {
  const url = `${baseApi}/users/self/mfa-preferences`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data
  });
}

export default {
  getSelf,
  getUser,
  getUserConfigPattern,
  getUsersList,
  getUsersListConfigPattern,
  updateUserConfigPattern,
  createOrganizationUser,
  getOrganizationUsersList,
  getOrganizationSubscriptions,
  updateOrganizationSubscriptions,
  getStandardRoles,
  getOrganizationRoles,
  getOrganizationRolesConfigPattern,
  getOrganizationActiveSeats,
  getProductSolutions,
  createCustomRole,
  getOrganizationUserCountForRole,
  getOrganizationRoleDetail,
  updateOrganizationCustomRole,
  deleteOrganizationCustomRole,
  putBulkAssign,
  putBulkReassign,
  updateUserOrganizationRoles,
  resendInvite,
  getOrganizationLanguages,
  getCobrandLanguages,
  getCobrandLanguagesConfigPattern,
  getUsagePlans,
  createApiUser,
  getApiUsersList,
  getApiUsersListConfigPattern,
  getApiUserDetails,
  updateApiUser,
  deleteApiUser,
  getAggregatedPermissionsPerId,
  getSelfOrganizations,
  updateUserByAction,
  getListOfUsersByIds,
  getAggregatedPermissionsPerIdConfigPattern,
  updateAttributeRestrictions,
  getAttributeRestrictions,
  getAttributeRestrictionType,
  enforceUser,
  getSelfConfigPattern,
  exportUserReport,
  exportUserReportStatus,
  exportUserReportDownload,
  resetMfaPreferences,
  setSelfMfaPreferences
};
