import waRequest from './wa-request';

export const apiBase = '/exposures/v1';

/**
 * Initiate authenticated GET ${apiBase}/exposures/intel
 * Gets a list of exposure summaries for various intel type.
 *
 * This API can serve both "intel only" and "exposures" use cases.
 *
 * If the user making the request has client restrictions limiting their access to specific intel keywords, the API will only support returning intel tagged with accessible keywords.
 *
 * @param {Object} config
 * @param {Object} config.params Querystring parameters
 * @param {string} [config.params.locale = 'en'] The requested language to return the airport names in ISO 639-1 format in conjunction with ISO 15924.  Used as a query parameter to indicate the client wants the returned content translated in the specified language. Note that not all fields on a response support translation and that only specific languages are supported.
 * @param {number} [config.params.page=0] Zero-based page index
 * @param {number} [config.params.size=10] The size of the page to be returned
 * @param config.params.sort Sorting criteria in the format: property,(asc|desc).  Sortable attributes for this API include: beginDate, modifiedDate, and publishedDate. Requests to sort other attributes will be rejected.
 * @param {Object} config.data Body of request
 * @param {string} config.data.activeWithinStart date-time. Begin of a date-time range to use to filter the intel. If provided activeWithinEnd must also be provided and the values must form a valid range. The filter will be applied against the beginDate and endDate of the intel. Note that expired intel will not be returned by default, even if the search range is in the past. includeExpired must be set true to see expired intel. By default all active intel will be returned if a range is not supplied.
 * @param {string} config.data.activeWithinEnd date-time. End of a date-time range to use to filter the intel. If provided activeWithinStart must also be provided and the values must form a valid range. The filter will be applied against the beginDate and endDate of the intel. Note that expired intel will not be returned by default, even if the search range is in the past. includeExpired must be set true to see expired intel. By default all active intel will be returned if a range is not supplied.
 * @param {Array<string>=} config.data.alertSeverities One or more of [INFORMATIONAL, WARNING, CRITICAL, CAUTION]. The severity levels of alerts to include in the response. Note that each severity level requires a distinct permission. Users must have permission to all requested severities across any of their roles or the request will be rejected. Note that this filter only applies to ALERT and FLASH_ALERT type intel and will only have an impact if intelTypes filter includes at least one of these types. This filter is required when when requesting ALERT or FLASH_ALERT type intel.
 * @param {Array<string>=} config.data.categoryIds One or more uuid category ids. List of ids of categories to filter intel by. If provided only intel associated with the provided categories or their children will be returned. By default intel is returned regardless of category.
 * @param {Array<string>=} config.data.incidentImpactLevels One or more of [MINIMAL, LOW, MODERATE, HIGH, SEVERE]. The impact levels of incidents to include in the response. Note that this filter will only be applied to INCIDENT type intel and will only have an impact if intelTypes filter includes INCIDENT. The request will be rejected if the user making the request does not have access to the requested impact levels based on their client restrictions. If INCIDENT intelTypes is provided and this filter is not set, the filter will default to all impact levels if the user does not have any client restrictions. If the user has client restrictions for specific incident impact levels, a default filter will be applied containing all the incident impact levels the user has access to.
 * @param {boolean} [config.data.includeExpired=false] Whether or not expired intel should be included in the result. Defaults to false. Note that there are permission requirements to access expired intel depending on intel type (VIEW_HISTORICAL_INCIDENTS, VIEW_HISTORICAL_ALERTS). If user is missing both of these permissions and requests includeExpired=true the request will be rejected. If they have at least one of the permissions, the request will be accepted and the response will include the expired content they have access to.
 * @param {boolean} [config.data.includeSubOrganizations=true] If true, includes child organizations in the query. If false, only the provided organizations will be included. The request will be rejected if this parameter is false and root organization is provided in the organizationsIds.
 * @param {Array<string>=} config.data.intelTypes One or more of [ALERT, FLASH_ALERT, INCIDENT]. Body of request List of type of Intel to include in the response. The user must have the appropriate permissions to request each type.
 * @param {Array<string>=} config.data.searchTerms List of search terms on which to filter intels. Filtering aims to include or exclude intels which contain at least one of the provided search terms. The filtering is case insensitive and is applied against these intel's fields: fullTitle, gist, summary, background, advice, resources. If requested, this parameter must require at least one string, empty strings are not allowed.
 * @param {string} config.data.searchMode One of [INCLUDE, EXCLUDE]. Whether to exclude or include intels that contain the provided search terms. Defaults to INCLUDE if not supplied along with the search terms.
 * @param {boolean} [config.data.includeExposures=false] Whether or not counts of exposures should be included in the response.
 * @param {Array<string>=} config.data.locationIds List of ids of locations to filter intel by. If provided only intel associated with the provided locations or their children will be returned. By default intel is returned regardless of location.
 * @param {boolean} [config.data.includeParentLocations=false] Whether or not to include intel tagged explicitly to any of the hierarchical parent locations of the locations included in the locationIds parameter. For example, if the client requests Florida (32371), then the response should also include intel tagged directly with United States (6), North America (2), and Worldwide (1). This parameter will not have any impact if locationIds is not provided.
 * @param {boolean} [config.data.requireSiteExposures=false] Whether or not to filter intel to only those which contain site exposures. This applies to the site exposure count. Note that access to exposure counts requires additional permissions. If this parameter is set to true, the user must have access to persons or sites in all of the the Organizations identified by the organizationIds parameter. If user is missing permission to both of these entities in any of the organizations, the request will be rejected. If the user has partial permissions to any of the organizations (i.e. access to persons but not sites) the response to account for this in the data and aggregations. Note that this filter applies to ALERT, FLASH_ALERT and INCIDENT type intel.
 * @param {boolean} [config.data.requirePersonExposures=false] Whether or not to filter intel to only those which contain person exposures. This applies to the "Now through alert end" exposure count. Note that access to exposure counts requires additional permissions. If this parameter is set to true, the user must have access to persons or sites in all of the the Organizations identified by the organizationIds parameter. If user is missing permission to both of these entities in any of the organizations, the request will be rejected. If the user has partial permissions to any of the organizations (i.e. access to persons but not sites) the response to account for this in the data and aggregations. Note that this filter only applies to ALERT and FLASH_ALERT type intel as we do not currently calculate person exposure to INCIDENT type intel. If user includes INCIDENT in the intelTypes filter then all incidents will be returned regardless of exposure. If you only want to see alerts impacting people you should exclude INCIDENT from the intelTypes filter.
 * @param {Array<string>} config.data.organizationIds an array of uuids, the organizations to filter by. ONLY required if
 * @param {Array} config.data.polygons GeoJson providing the shape of the Alert exposure.
 * @param {Array<number>} config.data.intelKeywordIds ds of the Intel Keywords to filter intel by. If provided, only intel associated with the provided keywords will be returned. Request with Keywords that are not enabled will be rejected. The request will be rejected if the user does not have access to the requested keywords based on their client restrictions. If not provided and the user has client restrictions for specific keywords, a default filter will be applied containing all keywords the user has access to. If the user does not have any client restrictions the intel will be returned regardless of keyword.
 *
 * @return {Promise}
 * */
function getAlerts(config) {

  const {
    params,
    data
  } = config;

  const url = `${apiBase}/exposures/intel`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params,
    data
  });
}

/**
 * @param {string} alertId
 * @param {string[]} organizationIds
 * @param {string} locale
 * @param {string} includeSubOrganizations
 * @param {string} signal
 *
 * @return {Promise}
 */
function getAlertSummary(alertId, organizationIds, locale, includeSubOrganizations, signal) {

  const config = {
    pathParams: {alertId},
    params: {organizationIds, locale, includeSubOrganizations},
    signal
  };

  return getAlertSummaryConfigPattern(config);

}

function getAlertSummaryConfigPattern(config) {

  const {
    pathParams: {alertId},
    params: {organizationIds, locale, includeSubOrganizations},
    signal
  } = config;

  const url = `${apiBase}/exposures/intel/alerts/${alertId}/summaries`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      alertId,
      organizationIds,
      locale,
      includeSubOrganizations
    }
  });

}

function getIncidentSummary({pathParams: {incidentId}, params: {organizationIds, includeSubOrganizations, locale, signal}}) {
  const url = `${apiBase}/exposures/intel/incidents/${incidentId}/summaries`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      incidentId,
      organizationIds,
      includeSubOrganizations,
      locale
    }
  });
}

/**
 *
 * @param alertId
 * @param organizationIds
 * @param locale
 * @param customDuration
 * @param customDuration.startDate
 * @param customDuration.endDate
 * @returns {Promise}
 */
function getCustomDuration(alertId, organizationIds, locale, {startDate, endDate}) {
  const url = `${apiBase}/exposures/intel/alerts/${alertId}/custom`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      alertId,
      organizationIds,
      locale,
      startDate,
      endDate
    }
  });
}

function initiateAlertsExport(exposureType, alertId, organizationIds, filterParams, includeSubOrganizations) {
  const url = `${apiBase}/exposures/intel/alerts/${alertId}/export`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationIds,
      exposureType,
      includeSubOrganizations,
      ...filterParams
    }
  });
}

/**
 * Request all pages of alerts based on the filter, will loop through pagination to get all alerts
 *
 * @param filter
 * @param locale
 * @param sort
 * @returns {Promise<{data: *}>}
 */
function getAllAlerts(filter = {}, locale = 'en', sort = ['publishedDate,desc']) {
  const url = `${apiBase}/exposures/intel`;

  return waRequest.requestAllPages({
    url: url,
    method: 'post',
    params: {
      locale,
      size: 1000,
      sort
    },
    data: filter
  })
    .then(pagedResults => {
      return waRequest.extractAllPagesResult(pagedResults, 'data.content');
    });
}

// beginDate, modifiedDate, and publishedDate
function exportAlerts(config) {

  const {
    params: {locale, exportType, sort='beginDate,desc'},
    signal,
    data: {
      intelTypes,
      activeWithinStart,
      activeWithinEnd,
      alertSeverities,
      incidentImpactLevels,
      categoryIds,
      locationIds,
      organizationIds,
      requirePersonExposures,
      requireSiteExposures,
      includeExpired,
      searchTerms,
      searchMode,
      intelKeywordIds
    }
  } = config;

  const url = `${apiBase}/exposures/intel/download`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      activeWithinStart,
      activeWithinEnd ,
      intelTypes,
      alertSeverities ,
      incidentImpactLevels,
      categoryIds,
      locationIds,
      includeExpired,
      organizationIds,
      requirePersonExposures,
      requireSiteExposures,
      searchTerms,
      searchMode,
      intelKeywordIds
    },
    params: {
      locale,
      exportType,
      sort
    },
    signal,
    responseType: 'blob'
  })
    .catch(err => {
      console.error('err:', err)
      throw new Error(err)
    });
}

function exportAffectedSites(config) {

  const {
    params: {
      exportFormat,
      includeNames,
      includeIds,
      includeSiteType,
      includeVulnerabilityRadius,
      includeOrganization,
      includeCoordinates,
      includeAddress,
      includeEmployeeCount,
      includePOCs,
      includeNotes,
      organizationIds,
      includeSubOrganizations,
      exposureType,
      versionNumber,
      createUrl,
      alertId,
      includeDistanceFromExposureArea
    },
    signal
  } = config;

  const url = `${apiBase}/exposures/intel/alerts/${alertId}/export-sites`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationIds,
      includeSubOrganizations,
      exposureType,
      versionNumber,
      createUrl,
      exportFormat,
      includeNames,
      includeIds,
      includeSiteType,
      includeVulnerabilityRadius,
      includeOrganization,
      includeCoordinates,
      includeAddress,
      includeEmployeeCount,
      includePOCs,
      includeNotes,
      includeDistanceFromExposureArea
    },
    signal
  });
}

function exportIncidentAffectedSites(config) {

  const {
    params: {
      exportFormat,
      includeNames,
      includeIds,
      includeSiteType,
      includeVulnerabilityRadius,
      includeOrganization,
      includeCoordinates,
      includeAddress,
      includeEmployeeCount,
      includePOCs,
      includeNotes,
      organizationIds,
      exposureType,
      versionNumber,
      createUrl,
      incidentId,
      includeDistanceFromExposureArea
    },
    signal
  } = config;

  const url = `${apiBase}/exposures/intel/incidents/${incidentId}/export-sites`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationIds,
      exposureType,
      versionNumber,
      createUrl,
      exportFormat,
      includeNames,
      includeIds,
      includeSiteType,
      includeVulnerabilityRadius,
      includeOrganization,
      includeCoordinates,
      includeAddress,
      includeEmployeeCount,
      includePOCs,
      includeNotes,
      includeDistanceFromExposureArea
    },
    signal
  })
}

function exportAlertAffectedOccupants(config) {

  const {
    params: {
      organizationIds,
      includePOCs,
      createUrl,
      alertId,
      locale,
      sort,
      accountInfo,
      includeOrganizations,
      addresses,
      emails,
      emergencyContacts,
      names,
      phones,
      optionalFields,
      mobileLocations,
      includePersonTypes,
      includeSites
    },
    signal
  } = config;

  const url = `${apiBase}/exposures/intel/alerts/${alertId}/export-occupants`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationIds,
      includePOCs,
      createUrl,
      locale,
      sort,
      accountInfo,
      includeOrganizations,
      addresses,
      emails,
      emergencyContacts,
      names,
      phones,
      optionalFields,
      mobileLocations,
      includePersonTypes,
      includeSites
    },
    signal
  });
}

function exportIncidentAffectedOccupants(config) {

  const {
    params: {
      organizationIds,
      incidentId,
      includePOCs,
      createUrl,
      locale,
      sort,
      accountInfo,
      includeOrganizations,
      addresses,
      emails,
      emergencyContacts,
      names,
      phones,
      optionalFields,
      mobileLocations,
      includePersonTypes,
      includeSites
    },
    signal
  } = config;

  const url = `${apiBase}/exposures/intel/incidents/${incidentId}/export-occupants`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationIds,
      includePOCs,
      createUrl,
      locale,
      sort,
      accountInfo,
      includeOrganizations,
      addresses,
      emails,
      emergencyContacts,
      names,
      phones,
      optionalFields,
      mobileLocations,
      includePersonTypes,
      includeSites
    },
    signal
  })
}

function getAllIntelExposures(config) {

  const {
    params: {
      locale,
      sort = ['publishedDate,desc']
    },
    data,
    signal
  } = config;

  const url = `${apiBase}/exposures/intel`;

  return waRequest.requestAllPages({
    url: url,
    method: 'post',
    params: {
      locale,
      size: 1000,
      sort
    },
    signal,
    data
  })
    .then(pagedResults => {
      return waRequest.extractAllPagesResult(pagedResults, 'data.content');
    });
}

function getSiteExposures(config) {

  const {
    pathParams: {siteId},
    params: {
      locale,
      page,
      size,
      sort = 'beginDate,desc'
    },
    data,
    signal
  } = config;

  const url = `${apiBase}/exposures/intel/sites/${siteId}/history`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      locale,
      page,
      size,
      sort
    },
    data,
    signal
  });
}

function exportSiteExposures(config) {

  const {
    pathParams: {siteId},
    params: {
      locale,
      sort = 'beginDate,desc'
    },
    data
  } = config;

  const url = `${apiBase}/exposures/intel/sites/${siteId}/history/download`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    responseType: 'blob',
    params: {
      locale,
      sort
    },
    data
  });
}

export default {
  getAlertSummary,
  getIncidentSummary,
  initiateAlertsExport,
  getCustomDuration,
  getAllAlerts,
  exportAlerts,
  getAllIntelExposures,
  exportAffectedSites,
  exportIncidentAffectedSites,
  getSiteExposures,
  exportSiteExposures,
  exportAlertAffectedOccupants,
  exportIncidentAffectedOccupants,
  getAlertSummaryConfigPattern,
  getAlerts
};

