import waRequest from './wa-request';

import {LOCATION_TIERS} from '../../constants/rating-filters';
import {CATEGORY_IDS} from '../../constants/categories';
import {FILE_TYPE} from '../../constants/download';

const LOCATION = 'locations';
const REPORT = 'report';
const RATINGS = 'ratings';
const CUSTOM_RATING = 'custom-rating';

export const apiBase = '/ratings/v3';

//using the default overview id for the assessmentCategoryId.
const overviewId = CATEGORY_IDS.OVERALL;

const getRatingsRequestSingleton = waRequest.createRequestSingleton();

/**
 * Initiate authenticated POST ${apiBase}/locations/ratings
 *
 * @param languageCode
 * @param filter
 * @param organizationId
 * @param page
 * @param size
 * @param sort
 * @param includeEmptyRatings
 * @returns {Promise}
 */

function getLocationRatings(filter, organizationId, languageCode = 'en', page = 0, size = 25, sort = 'asc', includeEmptyRatings = false) {
  const url = `${apiBase}/${LOCATION}/${RATINGS}`;

  return getRatingsRequestSingleton({
    url: url,
    method: 'post',
    params: {
      organizationId,
      languageCode,
      page,
      size,
      sort,
      includeEmptyRatings
    },
    data: filter
  });
}

function getLocationRatingsConfigPattern({params: {
  filter,
  locale = 'en',
  page = 0,
  size,
  sort,
  organizationId,
  includeEmptyRatings = false
}}) {

  const url = `${apiBase}/${LOCATION}/${RATINGS}`;

  return getRatingsRequestSingleton({
    url: url,
    method: 'post',
    params: {
      organizationId,
      languageCode: locale,
      page,
      size,
      sort,
      includeEmptyRatings
    },
    data: filter
  });
}

/**
 * Initiate authenticated POST ${apiBase}/locations/ratings requests for all country ratings data
 *
 * @returns {Promise}
 */
function getAllCountryRatings(organizationId) {
  const url = `${apiBase}/${LOCATION}/${RATINGS}`;

  return waRequest.requestAllPages({
    url: url,
    method: 'post',
    params: {
      organizationId,
      size: 1000
    },
    data: {
      locationTiers: ['Country']
    }
  })
    .then(pagedResults => {
      return waRequest.extractAllPagesResult(pagedResults, 'data.content');
    });
}

/**
 * Initiate authenticated POST ${apiBase}/locations/ratings for province data within country
 * to include GeoJson data
 *
 * @param countryId
 * @param organizationId
 * @param languageCode
 * @returns {Promise}
 */
function getCountryProvincesRatingWithGeoJson(countryId, organizationId, languageCode = 'en') {
  const url = `${apiBase}/locations/ratings`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationId,
      languageCode,
      page: 0,
      size: 50,
      includeGeoJson: true,
      sort: 'locationName,asc'
    },
    data: {
      includeSubCategories: true,
      locationTiers: [LOCATION_TIERS.PROVINCE],
      locationFilter: {
        withinLocationIds: [countryId]
      }
    }
  });
}

/**
 * Initiate authenticated POST ${apiBase}/locations/ratings for country data
 * to include GeoJson data
 *
 * @param includeSubCategories
 * @param organizationId
 * @param languageCode
 * @param locationIds
 * @returns {Promise}
 */
function getCountriesRatingWithGeoJson(
  includeSubCategories, organizationId, languageCode = 'en', locationIds = undefined) {
  const url = `${apiBase}/locations/ratings`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationId,
      languageCode,
      page: 0,
      size: 500,
      includeGeoJson: true,
      sort: 'locationName,asc'
    },
    data: {
      includeSubCategories,
      locationTiers: [LOCATION_TIERS.COUNTRY],
      locationFilter: {
        withinLocationIds: locationIds
      }
    }
  });
}

/**
 * Initiate authenticated POST ${apiBase}/locations/ratings/report
 *
 * @param filter
 * @param locale
 * @param type
 * @param sort
 * @param assessmentCategoryId
 * @param organizationId
 * @returns {Promise}
 */
function getRatingsReport(filter, locale = 'en', type, sort, assessmentCategoryId = overviewId, organizationId, includeSubCategories = false) {
  const url = `${apiBase}/${LOCATION}/${RATINGS}/${REPORT}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    responseType: 'blob',
    params: {
      assessmentCategoryId,
      locale,
      type,
      sort,
      organizationId,
      includeSubCategories
    },
    data: filter
  });
}

/**
 * Initiate authenticated POST ${apiBase}/locations/custom-rating
 *
 * @param orgId
 * @param data
 * @returns {Promise}
 */
function uploadCustomRating(orgId, data) {
  const url = `${apiBase}/${LOCATION}/${CUSTOM_RATING}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationId: orgId
    },
    data: data
  });
}

/**
 * Initiate authenticated GET ${apiBase}/locations/${locationId}/categories/${categoryId}/intel
 *
 * @param locationId
 * @param categoryId
 * @param organizationId
 * @param locale
 * @returns {Promise}
 */
function getPublishedLocationIntelForLocationAndCategory(locationId, categoryId, organizationId, locale = 'en') {
  const url = `${apiBase}/${LOCATION}/${locationId}/categories/${categoryId}/intel`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale,
      organizationId,
      fillCategoryTree: true
    }
  });
}

/**
 * Initiate authenticated PUT /locations/{locationId}/custom-rating
 *
 * @param {{categoryId: string, status: string, rating: number, note: string}[]} customRatings
 * @param organizationId
 * @param locationId
 * @returns {Promise}
 */
function updateCategoryRatingForLocation(customRatings, organizationId, locationId) {
  const url = `${apiBase}/${LOCATION}/${locationId}/custom-rating`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    params: {
      organizationId
    },
    data: customRatings
  });
}


/**
 * Initiate authenticated GET ${apiBase}/locations/${locationId}/intel
 *
 * @param locationId
 * @param organizationId
 * @param locale
 * @param includeOverriddenRatings
 * @returns {Promise}
 */
function getPublishedLocationIntelForLocation(locationId, organizationId, locale = 'en', includeOverriddenRatings) {
  const url = `${apiBase}/${LOCATION}/${locationId}/intel`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale,
      organizationId,
      fillCategoryTree: true,
      includeOverriddenRatings
    }
  });
}

function getPublishedLocationIntelForLocationConfigPattern(
  {
    pathParams: {locationId},
    params: {organizationId, locale = 'en', includeOverriddenRatings}
  }) {
  const url = `${apiBase}/${LOCATION}/${locationId}/intel`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale,
      organizationId,
      fillCategoryTree: true,
      includeOverriddenRatings
    }
  });
}

function getCustomRatingTemplate(organizationId, locationTiers = [], type = FILE_TYPE.XLSX, locale = 'en') {
  const url = `${apiBase}/locations/custom-rating/template`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    responseType: 'blob',
    params: {
      organizationId,
      locationTiers,
      type,
      locale
    }
  });
}

function getCustomRatingReport(organizationId) {
  const url = `${apiBase}/locations/custom-rating/report`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    responseType: 'blob',
    params: {
      organizationId
    }
  });
}

function getLocationNotices(organizationId, languageCode, sort, page, size) {
  const url = `${apiBase}/locations/notices`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      organizationId,
      languageCode,
      sort,
      page,
      size
    }
  });
}

function getNotice(organizationId, noticeId, languageCode) {
  const url = `${apiBase}/locations/notices/${noticeId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      organizationId,
      languageCode
    }
  });
}

function deleteLocationNotice(organizationId, noticeId) {
  const url = `${apiBase}/locations/notices/${noticeId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete',
    params: {
      organizationId
    }
  });
}

function updateLocationNotice(payload) {
  const url = `${apiBase}/locations/notices`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: payload
  });
}

function translateNoticeContent(organizationId, payload) {
  const url = `${apiBase}/locations/notices/translate`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: payload,
    params: {
      organizationId
    }
  });
}

function uploadImage(organizationId, file) {
  const url = `${apiBase}/locations/image`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: file,
    params: {
      organizationId
    }
  });
}

function updateCustomRatingScale({data}) {
  const url = `${apiBase}/custom-rating-scale`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data,
  });
}

function getCustomRatingScale({pathParams: {organizationId}, signal}) {
  const url = `${apiBase}/custom-rating-scale/${organizationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    signal,
    method: 'get',
  });
}

function deleteCustomRatingScale({pathParams: {organizationId}}) {
  const url = `${apiBase}/custom-rating-scale/${organizationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete',
  });
}

export default {
  getLocationRatings,
  getAllCountryRatings,
  getRatingsReport,
  getPublishedLocationIntelForLocationAndCategory,
  updateCategoryRatingForLocation,
  getPublishedLocationIntelForLocation,
  getPublishedLocationIntelForLocationConfigPattern,
  getCountryProvincesRatingWithGeoJson,
  getCountriesRatingWithGeoJson,
  getCustomRatingTemplate,
  uploadCustomRating,
  getCustomRatingReport,
  getLocationNotices,
  deleteLocationNotice,
  updateLocationNotice,
  getNotice,
  translateNoticeContent,
  uploadImage,
  getLocationRatingsConfigPattern,

  updateCustomRatingScale,
  getCustomRatingScale,
  deleteCustomRatingScale
};
