import waRequest from './wa-request';

export const apiBase = '/reports/v1';

/**
 * Initiate authenticated GET ${apiBase}/dashboards/${reportId}
 *
 * @param reportId
 * @param includeDashboardUrl
 * @param locale
 * @param signal
 * @returns {Promise}
 */
function getReport({pathParams: {reportId}, params: {includeDashboardUrl, locale} = {}, signal}) {
  const url = `${apiBase}/reports/${reportId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      includeDashboardUrl,
      locale
    }
  });
}

/**
 * Initiate authenticated GET ${apiBase}/reports
 *
 * @returns {Promise}
 */
function getReports(locale = 'en') {
  const url = `${apiBase}/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {locale}
  });
}

/**
 * Initiate authenticated GET ${apiBase}/reports/${reportId}/schedules
 *
 * @returns {Promise}
 */
function getReportSchedules({pathParams: {reportId}, params: {recipientTypes, organizationId} = {}, signal}) {
  const url = `${apiBase}/reports/${reportId}/schedules`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {recipientTypes, organizationId},
    signal
  });
}

function getReportSchedule({pathParams: {reportId, scheduleId}, signal}) {
  const url = `${apiBase}/reports/${reportId}/schedules/${scheduleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal
  });
}

function addReportSchedule({pathParams: {reportId}, data, signal}) {
  const url = `${apiBase}/reports/${reportId}/schedules`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      scheduleName: data.scheduleName,
      frequencyOfDelivery: data.frequencyOfDelivery,
      recipientType: data.recipientType,
      organizationId: data.organizationId,
      deliveryMode: data.deliveryMode,
      schedule: {
        startDate: data.startDate,
        endDate: data.endDate,
        runAtTime: data.runAtTime,
        frequencyType: data.frequencyType,
        //// daily
        repeatDailyInterval: data.repeatDailyInterval,
        //// weekly
        daysOfWeek: data.daysOfWeek,
        //// monthly
        repeatMonthlyInterval: data.repeatMonthlyInterval,
        daysOfMonth: data.daysOfMonth
      },
      userScheduleFilterValues: data.userScheduleFilterValues,
      toOrganizationRecipientIds: data.toOrganizationRecipientIds
    },
    signal
  });
}

function updateReportSchedule({pathParams: {reportId, scheduleId}, data, signal}) {
  const url = `${apiBase}/reports/${reportId}/schedules/${scheduleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: {
      scheduleName: data.scheduleName,
      frequencyOfDelivery: data.frequencyOfDelivery,
      recipientType: data.recipientType,
      organizationId: data.organizationId,
      deliveryMode: data.deliveryMode,
      schedule: {
        startDate: data.startDate,
        endDate: data.endDate,
        runAtTime: data.runAtTime,
        frequencyType: data.frequencyType,
        //// daily
        repeatDailyInterval: data.repeatDailyInterval,
        //// weekly
        daysOfWeek: data.daysOfWeek,
        //// monthly
        repeatMonthlyInterval: data.repeatMonthlyInterval,
        daysOfMonth: data.daysOfMonth
      },
      userScheduleFilterValues: data.userScheduleFilterValues,
      toOrganizationRecipientIds: data.toOrganizationRecipientIds
    },
    signal
  });
}

function deleteReportSchedule({pathParams}) {
  const url = `${apiBase}/reports/${pathParams.reportId}/schedules/${pathParams.scheduleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

export default {
  getReport,
  getReports,
  getReportSchedules,
  getReportSchedule,
  addReportSchedule,
  updateReportSchedule,
  deleteReportSchedule
};
