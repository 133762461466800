import _ from 'lodash';

import authStorage from './auth-storage';
import {Amplify} from 'aws-amplify';
import {CookieStorage, Hub} from 'aws-amplify/utils';
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito';

import {ROUTE_URLS} from '../../constants/routes';
import {SELF_REGISTRATION_TYPES} from '../../constants/self-registration';
import selfRegistration from './self-registration';
import {
  confirmResetPassword,
  confirmSignIn,
  getCurrentUser,
  resetPassword,
  setUpTOTP,
  signIn as _signIn,
  signInWithRedirect,
  signOut,
  verifyTOTPSetup
} from 'aws-amplify/auth';

const AMPLIFY_CONFIG = {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_CLIENT_ID,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_AUTH_DOMAIN,
          scopes: [
            'aws.cognito.signin.user.admin',
            'email',
            'openid',
            'phone',
            'profile'
          ],
          redirectSignIn: [process.env.REACT_APP_AUTH_REDIRECT],
          redirectSignOut: [process.env.REACT_APP_AUTH_REDIRECT],
          responseType: 'code'
        },
        username: 'true'
      }
    }
  }
};

const FSSO_ERROR_MESSAGE = 'Already found an entry for username';

/**
 * Applies Amplify.configure and adds Hub listener to watch customOAuthState
 * events on auth channel. Upon event triggers redirectToEncodedUrl, used for
 * forwarding deep linkage
 */
function initialize() {
  Amplify.configure(AMPLIFY_CONFIG);

  cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({
    domain: process.env.REACT_AMPLIFY_COOKIE_DOMAIN,
    path: '/',
    expires: null,
    sameSite: 'strict',
    secure: process.env.REACT_AMPLIFY_COOKIE_DOMAIN !== 'localhost'
  }));

  const queryString = window.location.search;

  // check for state in query string and add listener to auth channel to redirect if found and signIn event
  if (queryString) {
    const params = new URLSearchParams(queryString);

    const encodedUrl = params.get('state');
    const errorDescription = params.get('error_description');

    // Retry FSSO if specific error case
    if (errorDescription && errorDescription.includes(FSSO_ERROR_MESSAGE)) {
      const emailDomain = _.last(errorDescription?.split('@'))?.trim();
      const isRetryBlocked = authStorage.getFssoRetryBlock();

      if (emailDomain && !isRetryBlocked) {
        authStorage.setFssoRetryBlock(true);

        selfRegistration.selfRegPoliciesExists({type: SELF_REGISTRATION_TYPES.FSSO, emailDomain})
          .then(results => {
            if (results?.data?.providerName) {
              federatedSignIn(results?.data?.providerName, encodedUrl)
                .then(() => null);
            }
          });
        return;
      }
      else {
        authStorage.setFssoRetryBlock(false);
      }
    }

    if (encodedUrl) {
      Hub.listen('auth', ({payload: {event}}) => {
        if (event === 'signIn') {
          authStorage.setFssoRetryBlock(false);
          authStorage.redirectToEncodedUrl(encodedUrl);
        }
      });
    }
  }

  authStorage.setFssoRetryBlock(false);
  Hub.listen('auth', ({payload: {event, data}}) => {
    if (event === 'customOAuthState') {
      authStorage.redirectToEncodedUrl(data);
    }
  });
}

/**
 * Returns Auth.currentSession true if resolves else false
 * @returns {Promise<boolean>}
 */
function hasCurrentSession() {
  return getCurrentUser()
    .then(() => true)
    .catch(() => false);
}

/**
 * Triggers Auth.signOut
 */
function logout() {
  return signOut()
    .then(() => null)
    .catch(() => null)
    .finally(() => {
      if (!authStorage.getFssoRetryBlock()) {
        window.location.assign(`${window.location.origin}${ROUTE_URLS.LOGIN}`);
      }
    });
}

/**
 * Clears local session information
 * Directs application to federatedSignIn
 *
 * Adds object with customState property set to encoded window location href
 * to facilitate deep link return upon login return
 */
function redirectToLogin() {
  if (!authStorage.getFssoRetryBlock()) {

    const encodedUrl = authStorage.encodeUrl(window.location.href);

    window.location.assign(`${window.location.origin}${ROUTE_URLS.LOGIN}?redirectUrl=${encodedUrl}`);
  }
}

function signIn(username, password, options) {
  return _signIn({username, password, options})
    .catch((err) => {
      //if there is already a users logged in sign out and retry
      if (err?.name === 'UserAlreadyAuthenticatedException') {
        return signOut()
          .then(() => _signIn({username, password, options}));
      }
      throw err;
    });
}

function forgotPassword(username, metadata) {
  return resetPassword({username, metadata});
}

function forgotPasswordSubmit(username, confirmationCode, newPassword) {
  return confirmResetPassword({username, confirmationCode, newPassword});
}

function completeNewPassword(challengeResponse) {
  return confirmSignIn({challengeResponse});
}

function federatedSignIn(provider, redirectUrl) {
  return signInWithRedirect({
    provider: {custom: provider},
    customState: redirectUrl
  })
    .catch((err) => {
      //if there is already a users logged in sign out and retry
      if (err?.name === 'UserAlreadyAuthenticatedException') {
        return signOut()
          .then(() => {
            return signInWithRedirect({
              provider: {custom: provider},
              customState: redirectUrl
            });
          });
      }
      throw err;
    });
}

function sendCustomChallengeAnswer(challengeResponse) {
  return confirmSignIn({challengeResponse});
}

function setUpTotp() {
  return setUpTOTP();
}

function verifyTotpToken({challengeAnswer}) {
  return verifyTOTPSetup({code: challengeAnswer});
}

function currentUser() {
  return getCurrentUser();
}

export default {
  initialize,
  logout,
  redirectToLogin,
  signIn,
  forgotPassword,
  forgotPasswordSubmit,
  completeNewPassword,
  federatedSignIn,
  hasCurrentSession,
  sendCustomChallengeAnswer,
  setUpTotp,
  currentUser,
  verifyTotpToken,
  confirmSignIn,
  signOut
};
