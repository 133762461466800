import waRequest from './wa-request';

const cancelableAirlineListSingleton = waRequest.createCancelableSingleton();

export const baseApi = '/airlines/v1';

/**
 * Initiate authenticated GET ${baseApi}/airlines/search
 *
 * @param {Object} params Contains nameSearch, codeSearch, page, sort, size, etc.
 * @returns {Promise}
 */
function getAirlinesList(params) {
  const url = `${baseApi}/airlines/search`;

  return cancelableAirlineListSingleton.requestAuthenticated({
    url: url,
    method: 'get',
    params
  });
}

const getAirlinesReport = (params) => {
  const url = `${baseApi}/airlines/report`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob',
    params,
  });
}


function airlineSearchList({
  params: {
    iataCodeSearch,
    nameSearch,
    airlineId,
    countryId,
    operationalStatus,
    descriptionSearch,
    rating,
    locale,
    page,
    size,
    sort
  }, signal}) {
  const url = `${baseApi}/airlines/search`;

  return waRequest.requestAuthenticated({
    url,
    method: 'get',
    params: {
      iataCodeSearch,
      nameSearch,
      airlineId,
      countryId,
      operationalStatus,
      descriptionSearch,
      rating,
      locale,
      page,
      size,
      sort
    },
    signal
  })
}

function updateAirlinesRatings({
  pathParams: {airlineId},
  data
}) {
  const url = `${baseApi}/airlines/${airlineId}/custom-rating`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'PUT',
    data
  })
}

export default {
  getAirlinesList,
  getAirlinesReport,
  airlineSearchList,
  updateAirlinesRatings
};
