import * as types from './types';
import {STEPS} from './utils';

const loginReducer = (
  state = {
    initialized: false,
    currentStep: STEPS.INITIAL,
    user: undefined,
    redirectUrl: undefined,
    loading: true,
    authError: null,
    passwordResetData: {},
    allowedLanguages: [],
    logoUrl: undefined,
    loginConfig: undefined,
    contactsConfig: undefined,
    loadingCobranding: true,
    mfaAttempt: 0
  },
  action
) => {
  switch (action.type) {

    case types.INITIALIZE:
      return {
        ...state,
        initialized: true,
        loading: false,
        currentStep: action.payload.currentStep,
        passwordResetData: action.payload.passwordResetData,
        redirectUrl: action.payload.redirectUrl
      };

    case types.SET_STEP:
      return {
        ...state,
        loading: false,
        currentStep: action.payload,
        authError: null
      };

    case types.SIGN_IN_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        authError: null
      };

    case types.SIGN_IN_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case types.SIGN_IN_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        authError: action.payload
      };

    case types.AUTO_SIGN_IN_FAILURE:
      return {
        ...state,
        initialized: true,
        currentStep: STEPS.TEMPORARY_PASSWORD_EXPIRED,
        user: {username: action.payload.username},
        loading: false
      };

    case types.RESEND_TEMPORARY_CREDENTIALS_REQUEST:
      return {
        ...state,
        loading: true,
        authError: null
      };

    case types.RESEND_TEMPORARY_CREDENTIALS_SUCCESS:
      return {
        ...state,
        currentStep: STEPS.RESEND_INVITE,
        loading: false
      };

    case types.RESEND_TEMPORARY_CREDENTIALS_FAILURE:
      return {
        ...state,
        currentStep: STEPS.INITIAL,
        loading: false
      };

    case types.START_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        loading: true,
        authError: null
      };

    case types.START_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
        currentStep: STEPS.RESET_PASSWORD_EMAIL_SENT,
        passwordResetData: action.payload
      };

    case types.START_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        loading: false
      };

    case types.COMPLETE_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        loading: true,
        authError: null
      };

    case types.COMPLETE_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        loading: false,
        authError: action.payload
      };

    case types.SUBMIT_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        passwordRestData: action.payload,
        loading: true,
        authError: null
      };

    case types.SUBMIT_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        authError: action.payload
      };

    case types.NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        loading: false,
        currentStep: STEPS.NEW_PASSWORD_REQUIRED,
        authError: null
      };

    case types.START_PASSWORD_RESET:
      return {
        ...state,
        loading: false,
        user: action.payload,
        currentStep: STEPS.RESET_PASSWORD_REQUEST,
        authError: null
      };

    case types.SUBMIT_USERNAME:
      return {
        ...state,
        currentStep: STEPS.PASSWORD_CREDS,
        user: action.payload,
        authError: null
      };

    case types.FSSO_LOGIN:
      return {
        ...state,
        currentStep: STEPS.FSSO_LOGIN,
        user: action.payload,
        authError: null
      };

    case types.START_NON_MEMBER_ID_REGISTRATION:
      return {
        ...state,
        loading: false,
        currentStep: STEPS.REGISTRATION_NON_MEMBER_ID_PROFILE,
        user: action.payload,
        authError: null
      };

    case types.START_MEMBER_ID_REGISTRATION:
      return {
        ...state,
        loading: false,
        currentStep: STEPS.REGISTRATION_MEMBER_ID_PROFILE,
        user: action.payload,
        authError: null
      };

    case types.COMPLETE_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
        authError: null
      };

    case types.COMPLETE_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        currentStep: STEPS.REGISTRATION_COMPLETE
      };

    case types.COMPLETE_REGISTRATION_DELAYED:
      return {
        ...state,
        loading: false,
        currentStep: STEPS.REGISTRATION_DELAYED
      };

    case types.COMPLETE_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        authError: action.payload
      };

    case types.LOGIN_COMPLETION:
      return {
        ...state,
        initialized: false,
        currentStep: STEPS.INITIAL,
        user: undefined,
        redirectUrl: undefined,
        loading: true,
        authError: null,
        passwordResetData: {}
      };

    case types.GET_COBRANDING_INFO_REQUEST:
      return {
        ...state,
        loadingCobranding: true,
        allowedLanguages: [],
        logoUrl: undefined,
        loginConfig: undefined,
        contactsConfig: undefined
      };

    case types.GET_COBRANDING_INFO_SUCCESS:
      return {
        ...state,
        loadingCobranding: false,
        allowedLanguages: action.payload.allowedLanguages,
        logoUrl: action.payload.logoUrl,
        loginConfig: action.payload.loginConfig,
        contactsConfig: action.payload.contactsConfig
      };

    case types.LOADING:
      return {
        ...state,
        loading: true
      };

    case types.RESET_TO_LOGIN:
      return {
        ...state,
        currentStep: STEPS.INITIAL,
        user: undefined,
        loading: false,
        authError: null,
        mfaAttempt: 0
      };

    case types.MFA_START:
      return {
        ...state,
        currentStep: STEPS.MFA_START,
        user: action.payload.user
      };

    case types.MFA_SUBMIT_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.MFA_SUBMIT_FAILURE:
      return {
        ...state,
        mfaAttempt: state.mfaAttempt + 1,
        loading: false
      };

    default:
      return state;
  }
};

export default loginReducer;
