import waRequest from './wa-request';
import _ from 'lodash';
import {DEFAULT_PARENT_ID, DATA_RESIDENCY_KEY, DEFAULT_CLIENT_URL_STRING_KEY} from '../../components/Organizations/constants';

export const baseApi = '/organizations/v1';

/**
 * Api interface with organization api
 * https://bitbucket.ijetcorp.com/projects/WC4/repos/wc4-api-docs/browse/src/docs/private/organizations/wc4-organizations_v1.yaml
 */

/**
 * Initiate authenticated POST /organizations/v1/organizations to create a new organization
 *
 * @param organization
 * @returns {Promise}
 */
function createOrganization(organization) {
  const url = `${baseApi}/organizations`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: organization
  });
}

/**
 * Initiate authenticated PUT /organizations/v1/organizations/${organizationId}
 *
 *
 * @param organizationData
 * @returns {Promise}
 */
function updateOrganization(organizationData) {
  const url = `${baseApi}/organizations/${organizationData.id}`;

  //dataResidency value can never be updated
  organizationData = _.omit(organizationData, DATA_RESIDENCY_KEY);
  if (organizationData.parentId !== DEFAULT_PARENT_ID && !_.isNil(organizationData.clientUrlString)) {
    //sub-orgs should never send a clientUrlString to a PUT update
    organizationData = _.omit(organizationData, DEFAULT_CLIENT_URL_STRING_KEY);
  }

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: organizationData
  });
}

/**
 * Initiate authenticated GET /organizations/v1/organizations/${organizationId}
 *
 * @param organizationId
 * @param locale
 * @returns {Promise}
 */
function getOrganization(organizationId, locale) {
  const url = `${baseApi}/organizations/${organizationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {locale}
  });
}

/**
 * Initiate authenticated DELETE /organizations/v1/organizations/${organizationId}
 *
 * @param organizationId
 * @param deleteChildOrgs
 * @returns {Promise}
 */
function deleteOrganization(organizationId, deleteChildOrgs) {
  const url = `${baseApi}/organizations/${organizationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete',
    params: {
      deleteChildOrgs
    }
  });
}

/**
 * Initiate authenticated GET /organizations/v1/organizations/${organizationId}
 *
 * @param organizationId
 * @param locale
 * @returns {Promise}
 */
function getOrganizationUntilSuccess(organizationId, locale) {
  const url = `${baseApi}/organizations/${organizationId}`;

  return waRequest.requestWithRetry({url: url, method: 'get', params: {locale}}, 20);
}

/**
 * Initiate authenticated GET /organizations/v1/clientUrlStrings/exists
 *
 * @param clientUrlString
 * @returns {Promise}
 */
function getClientUrlStringExists(clientUrlString) {
  const url = `${baseApi}/clientUrlStrings/exists`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      clientUrlString
    }
  });
}

/**
 * Initiate authenticated GET /organizations/v1/organizations/${organizationId}/license
 *
 * @param organizationId
 * @returns {Promise}
 */
function getOrganizationLicense(organizationId) {
  const url = `${baseApi}/organizations/${organizationId}/license`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 *
 * @param organizationId
 * @param licenseCount
 * @returns {Promise}
 */
function setOrganizationLicense(organizationId, licenseCount) {
  const url = `${baseApi}/organizations/${organizationId}/license`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: licenseCount
  });
}

/**
 * Initiate authenticated GET /organizations/v1/organizations/${organizationId}/children
 *
 * @param organizationId
 * @param page
 * @param size
 * @param sort
 * @param searchFilter
 * @returns {Promise}
 */
function getOrganizationChildren(organizationId, page, size, sort, searchFilter) {
  const url = `${baseApi}/organizations/${organizationId}/children`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      page,
      size,
      sort,
      searchFilter
    }
  });
}

/**
 * Initiate authenticated GET /organizations/v1/organizations/${organizationId}/children requests for all children
 *
 * @param organizationId
 * @returns {Promise}
 */
function getAllOrganizationChildren(organizationId) {
  const url = `${baseApi}/organizations/${organizationId}/children`;

  return waRequest.requestAllPages({
    url: url,
    method: 'get',
    params: {
      size: 1000
    }
  })
    .then(pagedResults => {
      return waRequest.extractAllPagesResult(pagedResults, 'data.content');
    });
}

function getAllOrganizationChildrenConfigPattern({pathParams: {organizationId}, sync}) {
  const url = `${baseApi}/organizations/${organizationId}/children`;

  return waRequest.requestAllPages({
    url: url,
    method: 'get',
    params: {
      size: 1000
    },
    sync
  })
    .then(pagedResults => {
      return waRequest.extractAllPagesResult(pagedResults, 'data.content');
    });
}

/**
 * Initiate authenticated GET /organizations/v1/organizations/${organizationId}/hierarchy:
 *
 * @param organizationId
 * @returns {Promise}
 */
function getOrganizationHierarchy(organizationId) {
  const url = `${baseApi}/organizations/${organizationId}/hierarchy`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function getOrganizationHierarchyConfigPattern({pathParams: {organizationId}, sync}) {
  const url = `${baseApi}/organizations/${organizationId}/hierarchy`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    sync
  });
}

/**
 * Initiate authenticated PUT /organizations/v1/organizations/${organizationId}/hierarchy:
 *
 * @param organizationId
 * @param maxAvailableSeats
 * @returns {Promise}
 */
function setOrganizationMaxSeatCount(organizationId, maxAvailableSeats) {
  const url = `${baseApi}/organizations/${organizationId}/seats`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: maxAvailableSeats
  });
}

function getConfiguration(organizationId, configurationType) {
  const url = `${baseApi}/organizations/${organizationId}/configurations/${configurationType}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function getConfigurationByConfig({signal, pathParams: {organizationId, configurationType}}) {
  const url = `${baseApi}/organizations/${organizationId}/configurations/${configurationType}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal
  });
}

function addConfiguration(organizationId, values, configurationType) {
  const url = `${baseApi}/organizations/${organizationId}/configurations/${configurationType}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: values
  });
}

function updateConfiguration(organizationId, values, configurationType) {
  const url = `${baseApi}/organizations/${organizationId}/configurations/${configurationType}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: values
  });
}

function deleteConfiguration(organizationId, configurationType) {
  const url = `${baseApi}/organizations/${organizationId}/configurations/${configurationType}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

function getConfigurationConfigPattern({pathParams: {organizationId, configurationType} = {}, signal}) {
  const url = `${baseApi}/organizations/${organizationId}/configurations/${configurationType}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal
  });
}

function createConfigurationConfigPattern({pathParams:{organizationId, configurationType}, data}) {
  const url = `${baseApi}/organizations/${organizationId}/configurations/${configurationType}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data
  });
}

function updateConfigurationConfigPattern({pathParams:{organizationId, configurationType}, data}) {
  const url = `${baseApi}/organizations/${organizationId}/configurations/${configurationType}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data
  });
}

function deleteConfigurationConfigPattern({pathParams:{organizationId, configurationType}}) {
  const url = `${baseApi}/organizations/${organizationId}/configurations/${configurationType}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

function getOrganizationsWithFeatureSubscribed({params: {featureSubscribed}}) {
  const url = `${baseApi}/organizations`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      featureSubscribed
    }
  });
}

function getOrganizationTypes({params: {locale}}) {
  const url = `${baseApi}/organizations/types`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });
}


function getOrganizationSearch({params: {locale, page, size, sort, searchFilter, level, status, types}}) {
  const url = `${baseApi}/organizations/search`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale,
      page,
      size,
      sort,
      searchFilter,
      level: level ?? "ALL",
      status,
      types
    }
  });
}

function getMobileConfigurations({pathParams: {organizationId}}) {
  const url = `${baseApi}/organizations/${organizationId}/mobile-configurations`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
  });
}

function getOrganizationRecipients({pathParams: {organizationId}, params: {page, size, filterString}}){
  const url = `${baseApi}/organizations/${organizationId}/recipients`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      page,
      size,
      filterString
    }
  });
}

function createOrganizationRecipient({pathParams: {organizationId}, data}){
  const url = `${baseApi}/organizations/${organizationId}/recipients`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data
  });
}

function updateOrganizationRecipient({pathParams: {organizationId, recipientId}, data}){
  const url = `${baseApi}/organizations/${organizationId}/recipients/${recipientId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data
  });
}

function deleteOrganizationRecipient({pathParams: {organizationId, recipientId}}){
  const url = `${baseApi}/organizations/${organizationId}/recipients/${recipientId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

function getOrganizationSearchHistory() {
  const url = `${baseApi}/organizations/search-history`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
  });
}

function downloadOrganizations(exportId) {
  const url = `${baseApi}/organizations/search/export/${exportId}/report`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob'
  });
}

function initiateMessagesExport(configData) {
  const {pathParams: {locale, sort}, data} = configData;
  const {level, searchFilter, status, types} = data
  const parsedTypes = types?.map(item => item.value)

  const url = `${baseApi}/organizations/search/export`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      locale,
      sort,
      level,
      searchFilter,
      status,
      types: parsedTypes
    },
  });
}

function getMessagesExportStatus({id, successCallback}) {
  const url = `${baseApi}/organizations/search/export/${id}`;

  return waRequest.requestWithRetryUntilSuccess({
    url: url,
    method: 'get'
  },
  successCallback
  );
}

export default {
  createOrganization,
  updateOrganization,
  getOrganization,
  deleteOrganization,
  getClientUrlStringExists,
  getOrganizationUntilSuccess,
  getOrganizationLicense,
  setOrganizationLicense,
  getOrganizationChildren,
  getAllOrganizationChildren,
  getOrganizationHierarchy,
  setOrganizationMaxSeatCount,
  getConfiguration,
  addConfiguration,
  updateConfiguration,
  deleteConfiguration,
  getConfigurationByConfig,
  getConfigurationConfigPattern,
  createConfigurationConfigPattern,
  updateConfigurationConfigPattern,
  deleteConfigurationConfigPattern,
  getOrganizationsWithFeatureSubscribed,
  getOrganizationTypes,
  getMobileConfigurations,
  getOrganizationSearch,
  initiateMessagesExport,
  getMessagesExportStatus,
  downloadOrganizations,
  getOrganizationRecipients,
  createOrganizationRecipient,
  updateOrganizationRecipient,
  deleteOrganizationRecipient,
  getOrganizationSearchHistory,
  getAllOrganizationChildrenConfigPattern,
  getOrganizationHierarchyConfigPattern
};

