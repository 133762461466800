import _ from 'lodash';
import {replace} from 'connected-react-router';

import {defineMessages, FormattedMessage} from 'react-intl';

import * as types from './types';
import authStorageApi from '../../../services/api/auth-storage';
import {notificationActions} from '../notification';

import {checkForEmailRegistrationPolicies, checkFSSO, getSelfRegPolicyType, STEPS} from './utils';
import {BASE_URL} from '../../../constants/routes';
import {SELF_REGISTRATION_TYPES} from '../../../constants/self-registration';

const messages = defineMessages({
  invalidCobranding: {
    id: 'Redux.login.invalidCobranding',
    defaultMessage: 'the specific login page you requested does not exist... Re-directing you to the generic login page'
  },
  resendTempCredFailure: {
    id: 'Redux.login.resendTempCredFailure',
    defaultMessage: 'Error requesting new temporary credentials '
  },
  expiredLink: {
    id: 'Redux.login.expiredLink',
    defaultMessage: 'Registration link no longer valid please register manually through the registration process'
  },
  tooManyFailedMfa: {
    id: 'Redux.login.tooManyFailedMfa',
    defaultMessage: 'Too many failed attempts. Please verify code and retry.'
  },
  codeExpired: {
    id: 'Redux.login.codeExpired',
    defaultMessage: 'Code expired. Please sign in again to generate new email.'
  }
});

const CURRENT_STEP_URL_PARAM = 'step';
const USERNAME_URL_PARAM = 'username';
const RESET_CODE_URL_PARAM = 'temporaryCode';
const REDIRECT_URL_PARAM = 'redirectUrl';
const SELF_REG_LINK_ID_URL_PARAM = 'selfRegLinkId';

export const ERROR_CODE = {
  MEMBER_ID_NOT_EXIST: 'MemberIdNotExist',
  FAILED_CREATE_USER: 'FailedCreateUser'
};

/**
 *
 */
export const initialize = (urlSearchParams) => {
  return (dispatch, _getState) => {

    const currentStep = urlSearchParams.get(CURRENT_STEP_URL_PARAM);
    const redirectUrl = urlSearchParams.get(REDIRECT_URL_PARAM);
    const username = urlSearchParams.get(USERNAME_URL_PARAM);
    const code = urlSearchParams.get(RESET_CODE_URL_PARAM);
    const selfRegLinkId = urlSearchParams.get(SELF_REG_LINK_ID_URL_PARAM);

    dispatch(replace({search: ''}));

    switch (currentStep) {
      case STEPS.ACCOUNT_ACTIVATION:
        dispatch({
          type: types.INITIALIZE,
          payload: {
            currentStep: STEPS.AUTO_AUTH
          }
        });

        return dispatch(
          signInPassword({username, password: code}, true)
        );

      case STEPS.RESET_PASSWORD_SUBMIT:
        return dispatch({
          type: types.INITIALIZE,
          payload: {
            currentStep,
            passwordResetData: {username, code}
          }
        });

      case STEPS.REGISTRATION_LINK:
        return dispatch(registrationLinkFlow(selfRegLinkId));

      default:
        return dispatch({
          type: types.INITIALIZE,
          payload: {
            currentStep: STEPS.INITIAL,
            redirectUrl
          }
        });
    }
  };
};

/**
 * From STEPS.PASSWORD_CREDS submit username and password for login
 *
 * @param username
 * @param password
 * @param automatic
 * @returns {function(*, *, {auth: *, users: *}): *}
 */
export const signInPassword = ({username, password}, automatic) => {
  return (dispatch, _getState, {auth}) => {

    dispatch({type: types.SIGN_IN_PASSWORD_REQUEST});

    return auth.signIn(username, password)
      .then(user => {
        //check if new password is needed
        if (user?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
          dispatch({
            type: types.NEW_PASSWORD_REQUIRED
          });
        }

        // handle MFA
        else if (user?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE'
          || user?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_EMAIL_CODE') {
          dispatch({
            type: types.MFA_START,
            payload: {user}
          });
        }
        else {
          dispatch(handleLoginCompletion());
        }

        dispatch({type: types.SIGN_IN_PASSWORD_SUCCESS});
      })
      .catch(err => {
        if (automatic) {
          dispatch({
            type: types.AUTO_SIGN_IN_FAILURE,
            payload: {
              username,
              error: err.code
            }
          });
        }
        if (err?.name === 'UserAlreadyAuthenticatedException') {
          return auth.signOut()
            .then(() => dispatch(signInPassword({username, password}, automatic)));
        }
        else {
          dispatch({
            type: types.SIGN_IN_PASSWORD_FAILURE,
            payload: err.message
          });
        }
      });
  };
};

/**
 * From STEPS.NEW_PASSWORD_REQUIRED submit password from params and user information
 * from state to complete new password request
 *
 * @param password
 * @returns {function(*, *, {auth: *}): *}
 */
export const submitNewPassword = ({password}) => {
  return (dispatch, getState, {auth}) => {
    dispatch({type: types.SUBMIT_NEW_PASSWORD_REQUEST});

    // Collect confirmation code and new password, then
    return auth.completeNewPassword(password)
      .then(() => {
        dispatch({type: types.SUBMIT_NEW_PASSWORD_SUCCESS});
        dispatch(handleLoginCompletion());
      })
      .catch(error => dispatch({
        type: types.SUBMIT_NEW_PASSWORD_FAILURE,
        payload: error.code || error.message || error.__type
      }));
  };
};

/**
 * From STEPS.RESET_PASSWORD_REQUEST submit username to service to start
 * the password reset flow
 *
 * @param username
 * @returns {function(*, *, {auth: *}): *}
 */
export const startResetPassword = ({username}) => {
  return (dispatch, getState, {auth}) => {
    dispatch({type: types.START_PASSWORD_RESET_REQUEST});
    const locale = _.get(getState(), ['localization', 'locale']);

    return auth.forgotPassword(username, {language: locale})
      .then(data => {
        dispatch({
          type: types.START_PASSWORD_RESET_SUCCESS,
          payload: {
            username: username,
            destination: data?.CodeDeliveryDetails?.Destination
          }
        });
      })
      .catch(err => dispatch({
        type: types.START_PASSWORD_RESET_FAILURE,
        payload: err
      }));
  };
};

/**
 * From STEPS.RESET_PASSWORD_SUBMIT username, code, and new password to complete the reset
 * then upon success, complete the login process using username and new password
 * @param username
 * @param code
 * @param password
 * @returns {function(*, *, {auth: *}): *}
 */
export const completeResetPassword = ({username, code, password}) => {
  return (dispatch, _getState, {auth}) => {
    dispatch({type: types.COMPLETE_PASSWORD_RESET_REQUEST});

    return auth.forgotPasswordSubmit(username, code, password)
      .then(() => dispatch(signInPassword({username, password})))
      .catch(error => dispatch({
        type: types.COMPLETE_PASSWORD_RESET_FAILURE,
        payload: error.code || error.message || error.__type
      }));
  };
};

export const startPasswordReset = ({username}) => {
  return (dispatch, getState, {selfRegistration, auth}) => {

    return checkFSSO(username, getState, {selfRegistration, auth})
      .then(isFSSO => {
        if (!isFSSO) {
          return dispatch(startResetPassword({username}));
        }
        else {
          return dispatch({
            type: types.FSSO_LOGIN,
            payload: {username}
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
};

/**
 * From STEPS.INITIAL submit username to start the login flow. Check if FFSO reg policy exists,
 * if so, then FFSO login flow else go to STEP.PASSWORD_CREDS.
 * @param username
 * @returns {(function(*, *, {selfRegistration: *, auth: *}): (*|undefined))|*}
 */
export const submitUsername = (username) => {
  return (dispatch, getState, {selfRegistration, auth}) => {

    return checkFSSO(username, getState, {selfRegistration, auth}, true)
      .then(isFSSO => {
        if (!isFSSO) {
          return dispatch({
            type: types.SUBMIT_USERNAME,
            payload: {username}
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
};

/**
 * From STEPS.REGISTRATION_MEMBER_ID submit memberId value to  be verified and start the
 * member id registration flow.
 *
 * @param memberId
 * @returns {function(*): *}
 */
export const startRegistration = ({username, firstName, lastName}) => {
  return (dispatch, getState, {selfRegistration, auth}) => {
    dispatch({type: types.LOADING});

    return checkForEmailRegistrationPolicies(username, getState, {selfRegistration, auth})
      .then(emailDomainPolicy => {
        if (emailDomainPolicy !== SELF_REGISTRATION_TYPES.FSSO) {
          return dispatch({
            type: emailDomainPolicy?.exists ? types.START_NON_MEMBER_ID_REGISTRATION : types.START_MEMBER_ID_REGISTRATION,
            payload: {username, firstName, lastName}
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
};

/**
 * From  STEPS.REGISTRATION_MEMBER_ID_PROFILE and STEPS.REGISTRATION_NON_MEMBER_ID_PROFILE
 * submit user profile information to service to register user.
 *
 * Will check registration type if not exist will trigger error message else will
 * trigger registration
 *
 *
 * @param profile
 * @returns {function(*, *, {selfRegistration: *}): *}
 */
export const completeRegistration = (profile) => {
  return (dispatch, getState, {selfRegistration, auth}) => {
    dispatch({type: types.COMPLETE_REGISTRATION_REQUEST});
    const locale = _.get(getState(), ['localization', 'locale']);

    const {memberId, username, firstName, middleName, lastName, clientUrlString} = profile;

    const fullUser = {
      memberId: memberId,
      clientUrlString: clientUrlString,
      email: username,
      firstName: firstName,
      middleName: _.isEmpty(middleName) ? null : middleName,
      lastName: lastName,
      registrationLanguage: locale
    };

    return getSelfRegPolicyType(username, memberId, clientUrlString, getState, {selfRegistration, auth})
      .then(selfRegPolicyType => {
        switch (selfRegPolicyType) {
          case SELF_REGISTRATION_TYPES.FSSO:
            return SELF_REGISTRATION_TYPES.FSSO;
          case SELF_REGISTRATION_TYPES.VALID_EMAIL_DOMAIN:
            fullUser.memberId = undefined;
            return selfRegistration.signUp(fullUser);
          case SELF_REGISTRATION_TYPES.MEMBER_ID:
            return selfRegistration.signUp(fullUser);
          default:
            throw new Error('COMPLETE_REGISTRATION_FAILURE');
        }
      })
      .then((results) => {
        // FSSO should kick users to login flow
        if (results !== SELF_REGISTRATION_TYPES.FSSO) {
          if (results.data.delayed) {
            dispatch({type: types.COMPLETE_REGISTRATION_DELAYED});
          }
          else {
            dispatch({type: types.COMPLETE_REGISTRATION_SUCCESS});
          }
        }
      })
      .catch(() => {
        dispatch({
          type: types.COMPLETE_REGISTRATION_FAILURE,
          payload: _.isEmpty(memberId) ? ERROR_CODE.FAILED_CREATE_USER : ERROR_CODE.MEMBER_ID_NOT_EXIST
        });
      });
  };
};

export const setStep = (step) => {
  return (dispatch) => {
    return dispatch({
      type: types.SET_STEP,
      payload: step
    });
  };
};

/**
 * After authentication is complete this will transfer the user to loading page
 * @returns {function(...[*]=)}
 */
function handleLoginCompletion() {
  return (dispatch, getState) => {

    const redirectUrl = _.get(getState(), ['login', 'redirectUrl']);

    if (redirectUrl) {
      authStorageApi.redirectToEncodedUrl(redirectUrl);
      dispatch({type: types.LOGIN_COMPLETION});
    }
    else {
      dispatch({type: types.LOGIN_COMPLETION});
      dispatch(replace(BASE_URL));
    }
  };
}

/**
 * Using username from state requests resending of temporary login credentials
 * @returns {function(*, *, {users: *}): *}
 */
export function resendTemporaryCredentials() {
  return (dispatch, getState, {users}) => {
    dispatch({type: types.RESEND_TEMPORARY_CREDENTIALS_REQUEST});

    const username = _.get(getState(), ['login', 'user', 'username']);

    return users.resendInvite(username)
      .then(() => dispatch({
        type: types.RESEND_TEMPORARY_CREDENTIALS_SUCCESS
      }))
      .catch((err) => {
        dispatch({
          type: types.RESEND_TEMPORARY_CREDENTIALS_FAILURE,
          payload: err
        });
        dispatch(notificationActions.showErrorNotification(
          <FormattedMessage {...messages.resendTempCredFailure}/>
        ));
      });
  };
}

export const registrationLinkFlow = (linkId) => {
  return (dispatch, _getState, {selfRegistration}) => {

    dispatch({type: types.REGISTRATION_LINK_REQUEST});

    return selfRegistration.getSignUpLink({pathParams: {linkId}})
      .then(({data: results}) => {
        dispatch({type: types.REGISTRATION_LINK_SUCCESS});
        dispatch(startRegistration({
          username: results.email,
          firstName: results.firstName,
          lastName: results.lastName
        }));
      })
      .catch(() => {
        dispatch({type: types.REGISTRATION_LINK_FAILURE});
        dispatch(notificationActions.showErrorNotification(
          <FormattedMessage {...messages.expiredLink}/>
        ));
      });
  };
};

export const submitMfaCode = (code) => {
  return (dispatch, getState, {auth}) => {

    dispatch({type: types.MFA_SUBMIT_REQUEST});

    return auth.confirmSignIn({challengeResponse: code})
      .then(() => {
        dispatch({type: types.MFA_SUBMIT_SUCCESS});
        dispatch(handleLoginCompletion());
      })
      .catch((err) => {
        const toManyAttempts = _.get(getState(), ['login', 'mfaAttempt']) >= 2;

        if (toManyAttempts) {
          dispatch(notificationActions.showErrorNotification(
            <FormattedMessage {...messages.tooManyFailedMfa}/>
          ));
          return dispatch({type: types.RESET_TO_LOGIN});
        }
        else if (err?.message === 'Invalid session for the user, session is expired.') {
          dispatch(notificationActions.showErrorNotification(
            <FormattedMessage {...messages.codeExpired}/>
          ));
          return dispatch({type: types.RESET_TO_LOGIN});
        }

        dispatch({type: types.MFA_SUBMIT_FAILURE});
      });
  };
};

export const resetToLogin = () => {
  return (dispatch) => {
    return dispatch({type: types.RESET_TO_LOGIN});
  };
};
