import * as types from './types';
import _ from 'lodash';

import {defineMessages, FormattedMessage} from 'react-intl';
import {notificationActions} from '../notification';
import {sessionActions} from '../session';

import {parseNotificationPreferenceObject, parseLanguagePreferenceObject, NOTIFICATION_TYPES, getApiOptions} from './utils';
import {VIEW_ALL_USERS} from '../../../constants/permissions';

const messages = defineMessages({
  addPreferenceSuccess: {
    id: 'Redux.alertPreferences.addPreferenceSuccess',
    defaultMessage: 'Alert Notification Preference Added'
  },
  updateAlertPreferenceSuccess: {
    id: 'Redux.alertPreferences.updateAlertPreferenceSuccess',
    defaultMessage: 'Alert Notification Preference Updated'
  },
  updateIncidentPreferenceSuccess: {
    id: 'Redux.alertPreferences.updateIncidentPreferenceSuccess',
    defaultMessage: 'Incident Notification Preference Updated'
  },
  updateLanguagePreferenceSuccess: {
    id: 'Redux.alertPreferences.updateLanguagePreferenceSuccess',
    defaultMessage: 'Language Notification Preference Updated'
  },
  addLanguagePreferenceSuccess: {
    id: 'Redux.alertPreferences.addLanguagePreferenceSuccess',
    defaultMessage: 'Language Notification Preference Added'
  },
  deleteNotificationSuccess: {
    id: 'Redux.alertPreferences.deleteNotificationSuccess',
    defaultMessage: 'Notification Preference Deleted'
  },
  getPreferencesError: {
    id: 'Redux.alertPreferences.getPreferencesError',
    defaultMessage: 'There was a problem retrieving some of your preferences'
  },
  addPreferencesError: {
    id: 'Redux.alertPreferences.addPreferencesError',
    defaultMessage: 'There was a problem adding your preferences'
  },
  updatePreferencesError: {
    id: 'Redux.alertPreferences.updatePreferencesError',
    defaultMessage: 'There was a problem saving your preferences'
  },
  deletePreferencesError: {
    id: 'Redux.alertPreferences.deletePreferencesError',
    defaultMessage: 'There was a problem deleting your preferences'
  },
  addIncidentPreferenceSuccess: {
    id: 'Redux.alertPreferences.addIncidentPreferenceSuccess',
    defaultMessage: 'Incident Notification Preference Added'
  },
});


/**
 * Forms object and send request preferences.updateNotificationPreference
 *
 * @param body
 * @param type
 * @returns {Function}
 */
export const updateNotificationPreference = (body, type) => {
  return (dispatch, getState, {preferences}) => {

    dispatch({type: types.SAVE_NOTIFICATIONS_REQUEST});
    const parsed = parseNotificationPreferenceObject(body, type);

    return preferences.updateUserPreference(body.id, parsed, getApiOptions(getState))
      .then((result) => {
        dispatch({
          type: types.SAVE_NOTIFICATIONS_SUCCESS,
          payload: result.data
        });
        dispatch(notificationActions.showSuccessNotification(
          type === NOTIFICATION_TYPES.INCIDENT_NOTIFICATION
            ? <FormattedMessage {...messages.updateIncidentPreferenceSuccess}/>
            : <FormattedMessage {...messages.updateAlertPreferenceSuccess}/>
        ));
        dispatch(getAlertNotificationsPreferences());
      })
      .catch((error) => {
        dispatch({type: types.SAVE_NOTIFICATIONS_ERROR});
        dispatch(notificationActions.showErrorNotification(
          <FormattedMessage {...messages.updatePreferencesError}/>
        ));
        throw error;
      });
  };

};

export const getAlertNotificationsPreferences = () => {
  return (dispatch, getState, {preferences}) => {

    dispatch({type: types.GET_ALERT_NOTIFICATIONS_REQUEST});

    return preferences.getUserPreferences(NOTIFICATION_TYPES.ALERT_NOTIFICATION, getApiOptions(getState))
      .then((result) => {
        dispatch(sessionActions.getCurrentUser());
        return dispatch({
          type: types.GET_ALERT_NOTIFICATIONS_SUCCESS,
          payload: result.data
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ALERT_NOTIFICATIONS_ERROR,
          payload: error
        });
        dispatch(notificationActions.showErrorNotification(
          <FormattedMessage {...messages.getPreferencesError}/>
        ));
      });
  };
};

export const getIncidentNotificationsPreferences = (dispatchSelf = true) => {
  return (dispatch, getState, {preferences}) => {

    dispatch({type: types.GET_INCIDENT_NOTIFICATIONS_REQUEST});

    return preferences.getUserPreferences(NOTIFICATION_TYPES.INCIDENT_NOTIFICATION, getApiOptions(getState))
      .then((result) => {
        // Prevent unnecessary getCurrentUser call, it may have been call for alert preferences
        dispatchSelf && dispatch(sessionActions.getCurrentUser());
        return dispatch({
          type: types.GET_INCIDENT_NOTIFICATIONS_SUCCESS,
          payload: result.data
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_INCIDENT_NOTIFICATIONS_ERROR,
          payload: error
        });
        dispatch(notificationActions.showErrorNotification(
          <FormattedMessage {...messages.getPreferencesError}/>
        ));
      });
  };
};

export const deleteAlertNotificationPreference = (id) => {
  return (dispatch, getState, {preferences}) => {

    dispatch({type: types.DELETE_ALERT_NOTIFICATIONS_REQUEST});

    return preferences.deleteUserPreference(id, getApiOptions(getState))
      .then((result) => {
        dispatch({
          type: types.DELETE_ALERT_NOTIFICATIONS_SUCCESS,
          payload: result.data
        });
        dispatch(notificationActions.showSuccessNotification(
          <FormattedMessage {...messages.deleteNotificationSuccess}/>
        ));
        dispatch(getAlertNotificationsPreferences());
        dispatch(getIncidentNotificationsPreferences());
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_ALERT_NOTIFICATIONS_ERROR,
          payload: error
        });
        dispatch(notificationActions.showErrorNotification(
          <FormattedMessage {...messages.deletePreferencesError}/>
        ));
      });
  };
};

export const addNotificationPreference = (body, type) => {
  return (dispatch, getState, {preferences}) => {

    dispatch({type: types.ADD_NOTIFICATIONS_REQUEST});

    const parsed = parseNotificationPreferenceObject(body, type);

    return preferences.addUserPreference(parsed, getApiOptions(getState))
      .then((result) => {
        dispatch({
          type: types.ADD_NOTIFICATIONS_SUCCESS,
          payload: result.data
        });
        const message = type === NOTIFICATION_TYPES.INCIDENT_NOTIFICATION  ?
          <FormattedMessage {...messages.addIncidentPreferenceSuccess}/> :
          <FormattedMessage {...messages.addPreferenceSuccess}/>;

        dispatch(notificationActions.showSuccessNotification(message));
        dispatch(getAlertNotificationsPreferences());

      })
      .catch((error) => {
        dispatch({
          type: types.ADD_NOTIFICATIONS_ERROR,
          payload: error
        });
        dispatch(notificationActions.showErrorNotification(
          <FormattedMessage {...messages.addPreferencesError}/>
        ));
        throw error;
      });
  };
};

//////////////////////////////////////////////////////
/// LANGUAGE PREFERENCE start
///

function getLanguagePreference(dispatch, {preferences}, {userId, isSelf}) {
  dispatch({type: types.GET_LANGUAGE_NOTIFICATIONS_REQUEST, payload: userId});

  return preferences.getUserPreferences(NOTIFICATION_TYPES.NOTIFICATION_LANGUAGE, {userId, isSelf})
    .then((result) => {
      dispatch(sessionActions.getCurrentUser());
      return dispatch({
        type: types.GET_LANGUAGE_NOTIFICATIONS_SUCCESS,
        payload: result.data?.length ? result.data[0] : null
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_LANGUAGE_NOTIFICATIONS_ERROR,
        payload: error
      });
      dispatch(notificationActions.showErrorNotification(
        <FormattedMessage {...messages.getPreferencesError}/>
      ));
      throw error;
    });
}

function addLanguagePreference(dispatch, {preferences}, {body, userId, isSelf}) {
  dispatch({type: types.ADD_LANGUAGE_NOTIFICATIONS_REQUEST});

  const parsed = parseLanguagePreferenceObject(body);

  return preferences.addUserPreference(parsed, {userId, isSelf})
    .then((result) => {
      dispatch({
        type: types.ADD_LANGUAGE_NOTIFICATIONS_SUCCESS,
        payload: result.data
      });
      dispatch(notificationActions.showSuccessNotification(
        <FormattedMessage {...messages.addLanguagePreferenceSuccess}/>
      ));
    })
    .catch((error) => {
      dispatch({
        type: types.ADD_LANGUAGE_NOTIFICATIONS_ERROR,
        payload: error
      });
      dispatch(notificationActions.showErrorNotification(
        <FormattedMessage {...messages.addPreferencesError}/>
      ));
      throw error;
    });
}

function updateLanguagePreference(dispatch, {preferences}, {id, locale, userId, isSelf}) {
  dispatch({type: types.SAVE_LANGUAGE_NOTIFICATIONS_REQUEST});
  const parsed = parseLanguagePreferenceObject(locale);

  return preferences.updateUserPreference(id, parsed, {userId, isSelf})
    .then((result) => {
      dispatch({
        type: types.SAVE_LANGUAGE_NOTIFICATIONS_SUCCESS,
        payload: result.data
      });
      dispatch(notificationActions.showSuccessNotification(
        <FormattedMessage {...messages.updateLanguagePreferenceSuccess}/>
      ));
    })
    .catch((error) => {
      dispatch({type: types.SAVE_LANGUAGE_NOTIFICATIONS_ERROR});
      dispatch(notificationActions.showErrorNotification(
        <FormattedMessage {...messages.updatePreferencesError}/>
      ));
      throw error;
    });
}

export const getLoggedInUserLanguagePreference = () => {
  return (dispatch, _getState, {preferences}) => {
    const isSelf = true;
    return getLanguagePreference(dispatch, {preferences}, {isSelf});
  };
}

export const addLoggedInUserLanguagePreference = (body) => {
  return (dispatch, _getState, {preferences}) => {
    const isSelf = true;
    return addLanguagePreference(dispatch, {preferences}, {body, isSelf})
      .then(() => {
        dispatch(getLoggedInUserLanguagePreference());
      });
  };
};

export const updateLoggedInUserLanguagePreference = (id, locale) => {
  return (dispatch, _getState, {preferences}) => {
    const isSelf = true;
    return updateLanguagePreference(dispatch, {preferences}, {id, locale, isSelf})
      .then(() => {
        dispatch(getLoggedInUserLanguagePreference());
      });
  };
};

///
/// LANGUAGE PREFERENCE end
//////////////////////////////////////////////////////

export const getUserAggregatedPermissionsIds = (organizationId, userId, isSelf) => {
  return (dispatch, getState, {users}) => {
    dispatch({type: types.GET_AGG_PERMISSIONS_IDS_REQUEST, payload: {userId, isSelf}});
    const aggregatedPermissionIds = _.get(getState(), ['session', 'aggregatedPermissionIds']);
    if (isSelf) {
      return dispatch({
        type: types.GET_AGG_PERMISSIONS_IDS_SUCCESS,
        payload: aggregatedPermissionIds
      });
    }
    if (!aggregatedPermissionIds.includes(VIEW_ALL_USERS)) {
      return dispatch({
        type: types.GET_AGG_PERMISSIONS_IDS_SUCCESS,
        payload: []
      });
    }
    return users.getAggregatedPermissionsPerId(organizationId, userId)
      .then((result) => {
        dispatch({
          type: types.GET_AGG_PERMISSIONS_IDS_SUCCESS,
          payload: result.data
        });
      })
      .catch(() => {
        dispatch({type: types.GET_AGG_PERMISSIONS_IDS_ERROR});
      });
  };

};

export const updateFlaggedPreferences = (flags) => {
  return (dispatch, getState) => {
    const state = getState();
    const flagsFromStore = state?.preferences?.flaggedPreferences;

    const hasChanged = !_.isEqual(flags?.sort(), flagsFromStore?.sort());
    const shouldUpdate = (flags?.length && hasChanged);
    if (shouldUpdate) {
      return dispatch({
        type: types.SET_FLAGGED_NOTIFICATION_PREFERENCES,
        payload: flags
      })
    }
  };

};

