export const GET_PREFERENCES_REQUEST = 'preferences/GET_PREFERENCES_REQUEST';
export const GET_PREFERENCES_SUCCESS = 'preferences/GET_PREFERENCES_SUCCESS';
export const GET_PREFERENCES_ERROR = 'preferences/GET_PREFERENCES_ERROR';
export const GET_PREFERENCES_EMPTY = 'preferences/GET_PREFERENCES_EMPTY';

export const SAVE_PREFERENCES_REQUEST = 'preferences/SAVE_PREFERENCES_REQUEST';
export const SAVE_PREFERENCES_SUCCESS = 'preferences/SAVE_PREFERENCES_SUCCESS';
export const SAVE_PREFERENCES_ERROR = 'preferences/SAVE_PREFERENCES_ERROR';

export const GET_ALERT_NOTIFICATIONS_REQUEST = 'preferences/GET_ALERT_NOTIFICATIONS_REQUEST';
export const GET_ALERT_NOTIFICATIONS_SUCCESS = 'preferences/GET_ALERT_NOTIFICATIONS_SUCCESS';
export const GET_ALERT_NOTIFICATIONS_ERROR = 'preferences/GET_ALERT_NOTIFICATIONS_ERROR';

export const ADD_NOTIFICATIONS_REQUEST = 'preferences/ADD_NOTIFICATIONS_REQUEST';
export const ADD_NOTIFICATIONS_SUCCESS = 'preferences/ADD_NOTIFICATIONS_SUCCESS';
export const ADD_NOTIFICATIONS_ERROR = 'preferences/ADD_NOTIFICATIONS_ERROR';

export const SAVE_NOTIFICATIONS_REQUEST = 'preferences/SAVE_NOTIFICATIONS_REQUEST';
export const SAVE_NOTIFICATIONS_SUCCESS = 'preferences/SAVE_NOTIFICATIONS_SUCCESS';
export const SAVE_NOTIFICATIONS_ERROR = 'preferences/SAVE_NOTIFICATIONS_ERROR';

export const DELETE_ALERT_NOTIFICATIONS_REQUEST = 'preferences/DELETE_ALERT_NOTIFICATIONS_REQUEST';
export const DELETE_ALERT_NOTIFICATIONS_SUCCESS = 'preferences/DELETE_ALERT_NOTIFICATIONS_SUCCESS';
export const DELETE_ALERT_NOTIFICATIONS_ERROR = 'preferences/DELETE_ALERT_NOTIFICATIONS_ERROR';

export const GET_LANGUAGE_NOTIFICATIONS_REQUEST = 'preferences/GET_LANGUAGE_NOTIFICATIONS_REQUEST';
export const GET_LANGUAGE_NOTIFICATIONS_SUCCESS = 'preferences/GET_LANGUAGE_NOTIFICATIONS_SUCCESS';
export const GET_LANGUAGE_NOTIFICATIONS_ERROR = 'preferences/GET_LANGUAGE_NOTIFICATIONS_ERROR';

export const ADD_LANGUAGE_NOTIFICATIONS_REQUEST = 'preferences/ADD_LANGUAGE_NOTIFICATIONS_REQUEST';
export const ADD_LANGUAGE_NOTIFICATIONS_SUCCESS = 'preferences/ADD_LANGUAGE_NOTIFICATIONS_SUCCESS';
export const ADD_LANGUAGE_NOTIFICATIONS_ERROR = 'preferences/ADD_LANGUAGE_NOTIFICATIONS_ERROR';

export const SAVE_LANGUAGE_NOTIFICATIONS_REQUEST = 'preferences/SAVE_LANGUAGE_NOTIFICATIONS_REQUEST';
export const SAVE_LANGUAGE_NOTIFICATIONS_SUCCESS = 'preferences/SAVE_LANGUAGE_NOTIFICATIONS_SUCCESS';
export const SAVE_LANGUAGE_NOTIFICATIONS_ERROR = 'preferences/SAVE_LANGUAGE_NOTIFICATIONS_ERROR';

export const GET_AGG_PERMISSIONS_IDS_REQUEST = 'preferences/GET_AGG_PERMISSIONS_IDS_REQUEST';
export const GET_AGG_PERMISSIONS_IDS_SUCCESS = 'preferences/GET_AGG_PERMISSIONS_IDS_SUCCESS';
export const GET_AGG_PERMISSIONS_IDS_ERROR = 'preferences/GET_AGG_PERMISSIONS_IDS_ERROR';

export const GET_INCIDENT_NOTIFICATIONS_REQUEST = 'preferences/GET_INCIDENT_NOTIFICATIONS_REQUEST';
export const GET_INCIDENT_NOTIFICATIONS_SUCCESS = 'preferences/GET_INCIDENT_NOTIFICATIONS_SUCCESS';
export const GET_INCIDENT_NOTIFICATIONS_ERROR = 'preferences/GET_INCIDENT_NOTIFICATIONS_ERROR';

export const SET_FLAGGED_NOTIFICATION_PREFERENCES = 'preferences/SET_FLAGGED_NOTIFICATION_PREFERENCES';