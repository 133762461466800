import waRequest from './wa-request';

export const apiBase = '/preferences/v1';

/**
 * Initiate authenticated GET for user preferences
 *
 * @returns {Promise}
 */
function getUserPreference({pathParams: {userId, isSelf, id}}) {
  const url = isSelf ?
    `${apiBase}/user-preferences/${id}` : 
    `${apiBase}/users/${userId}/user-preferences/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * Initiate authenticated GET for user preferences
 *
 * @returns {Promise}
 */
function getUserPreferences(type, {userId, isSelf, includeAuditFlags}) {
  const url = isSelf ?
    `${apiBase}/user-preferences` : 
    `${apiBase}/users/${userId}/user-preferences`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      type,
      includeAuditFlags
    }
  });
}

// this wrapper is to use getUserPreference with hooks
function getUserPreferencesWrapper({pathParams: {userId}, params: {isSelf, type, includeAuditFlags}}) {
  return getUserPreferences(type, {userId, isSelf, includeAuditFlags});
}

/**
 * Initiate authenticated POST for user preferences
 *
 * @returns {Promise}
 */
function addUserPreference(body, {userId, isSelf}) {
  const url = isSelf ?
    `${apiBase}/user-preferences` : 
    `${apiBase}/users/${userId}/user-preferences`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: body
  });
}

// this wrapper is to use addUserPreference with hooks
function addUserPreferencesWrapper({data: body, pathParams: {userId, isSelf}}) {
  return addUserPreference(body, {userId, isSelf});
}

function addUserPreferencesSearchFilter({data: body}) {
  const url = `${apiBase}/user-preferences`
  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: body
  });
}

function updateUserPreferencesSearchFilter({data: body, pathParams: {preferenceId}}) {
  const url = `${apiBase}/user-preferences/${preferenceId}`
  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: body
  });
}

/**
 * Initiate authenticated PUT for user preferences
 *
 * @returns {Promise}
 */
function updateUserPreference(id, body, {userId, isSelf}) {
  const url = isSelf ?
    `${apiBase}/user-preferences/${id}` :
    `${apiBase}/users/${userId}/user-preferences/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: body
  });
}

// this wrapper is to use updateUserPreference with hooks
function updateUserPreferencesWrapper({data: body, pathParams: {userId, isSelf, id}}) {
  return updateUserPreference(id, body, {userId, isSelf});
}

/**
 * Initiate authenticated DELETE for user preferences
 *
 * @returns {Promise}
 */
function deleteUserPreference(id, {userId, isSelf}) {
  const url = isSelf ?
    `${apiBase}/user-preferences/${id}` : 
    `${apiBase}/users/${userId}/user-preferences/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

// this wrapper is to use deleteUserPreference with hooks
function deleteUserPreferencesWrapper({pathParams: {userId, isSelf, id}}) {
  return deleteUserPreference(id, {userId, isSelf});
}

/**
 * Initiate authenticated GET for self flagged preferences
 *
 * @returns {Promise}
 */
function getFlaggedPreferences() {
  const url = `${apiBase}/user-preferences/flagged`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * Initiate authenticated GET for managed user flagged preferences
 *
 * @returns {Promise}
 */
function getUserFlaggedPreferences({pathParams: {userId}}) {
  const url = `${apiBase}/users/${userId}/user-preferences/flagged`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function deleteFlaggedPreference({params: {type}}) {
  const url = `${apiBase}/user-preferences/flags`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete',
    params: {type}
  });
}

export default {
  getUserPreferences,
  addUserPreference,
  updateUserPreference,
  deleteUserPreference,
  getUserPreferencesWrapper,
  addUserPreferencesWrapper,
  deleteUserPreferencesWrapper,
  updateUserPreferencesWrapper,
  addUserPreferencesSearchFilter,
  updateUserPreferencesSearchFilter,
  getUserPreference,
  getFlaggedPreferences,
  getUserFlaggedPreferences,
  deleteFlaggedPreference
};
